import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { HomeComponent } from './home/home.component';
import { OrderCardComponent } from './pages/order-card/order-card.component';
import { ViewDetailsComponent } from './pages/order-history/view-details/view-details.component';
import { AuthGuard, ReverseAuthGuard } from './core';
import { AuthModule } from './pages/auth/auth.module';
import { AdminModule } from './pages/admin/admin.module';
import { BaseComponent } from './base/base.component';
import { OrderCardModule } from './pages/order-card/order-card.module';
import { OrderHistoryModule } from './pages/order-history/order-history.module';
import { VendorModule } from './pages/vendor/vendor.module';

const routes: Routes = [

  {
    path: 'auth',
    canActivate: [ReverseAuthGuard],
    loadChildren: () => import('./pages/auth/auth.module').then(() => AuthModule)
  },
  { path: 'home', component: HomeComponent },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [{
      path: 'admin',
      loadChildren: () => import('./pages/admin/admin.module').then(() => AdminModule),
    },
    {
      path: 'vendor',
      loadChildren: () => import('./pages/vendor/vendor.module').then(() => VendorModule),
    },
    {
      path: 'ordercard', 
      loadChildren: () => import('./pages/admin/admin.module').then(()=>OrderCardModule),
    },
    {
      path: 'orderhistoy', 
      loadChildren: () => import('./pages/order-history/order-history.module').then(()=> OrderHistoryModule),
    },
    { path: '', redirectTo: '', pathMatch: 'full', canActivate: [ReverseAuthGuard] },
			{ path: '**', redirectTo: '', pathMatch: 'full', canActivate: [ReverseAuthGuard] }
    ]
  },
  
  { path: '**', redirectTo: '', pathMatch: 'full', canActivate: [ReverseAuthGuard] }
  
,
  {
    path: 'view-details', component: ViewDetailsComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'add-user', component: AddUserComponent
  //   // canActivate: [AuthGuard]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }