import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../partials/confirmation-dialog/confirmation-dialog.component';
import { SharedService } from '../../../core/_services/shared/shared.service';
import { UserService } from '../../../core/_services/user/user.service';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.scss']
})
export class ModifyUserComponent implements OnInit {
  userForm: FormGroup;
  user: any;
  password: any;
  userName: any;
  fullName: any;
  branchId: any;
  email: any;
  mobileNumber: any;
  phoneNumber: any;
  role: any;
  designation: any;
  users = [];
  branches = [];
  roles = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      user: ['', [Validators.required]],
      // password: ['', [Validators.required]],
      // userName: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      // branchId: ['', [Validators.required]],
      email: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required, Validators.maxLength(10)]],
      phoneNumber: [''],
      role: ['', [Validators.required]],
      designation: ['', [Validators.required]],
    });
    this.userForm.valueChanges.subscribe(val => console.log(val))
    this.getAllUsers();
    // this.getAllBranches();
    this.getAllRoles();
  }

  get data() {
    if (this.userForm) {
      return this.userForm.controls;
    }
  }

  getAllUsers() {
    this.userService.getAllUsers().subscribe(
      (response) => {
        this.users = response["data"]["user"];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getAllBranches() {
    this.userService.getAllBranches().subscribe(
      (response) => {
        this.branches = response["data"]["branch"];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getAllRoles() {
    this.userService.getAllRoles().subscribe(
      (response) => {
        this.roles = response["data"]["role"];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  selectUser(event) {
    this.userForm.patchValue({
      userName: event.value.userName,
      fullName: event.value.fullName,
      branchId: event.value.branchId,
      email: event.value.email,
      mobileNumber: event.value.mobileNumber,
      phoneNumber: event.value.phoneNumber,
      role: event.value.role,
      designation: event.value.designation,
    });
  }

  updateUser() {
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      return;
    }
    this.userService.updateUser(this.userForm.value.user._id, this.userForm.value).pipe(
      map(res => {
        this.sharedService.openSuccessDialog(res.message, '')
      }), catchError(err => {
        console.log(err.error.message);
        throw err;
      })).subscribe();
  }

  deleteUser() {
    if (this.userForm.controls.user.invalid) {
      this.userForm.markAllAsTouched();
      return;
    }

    const title = 'Delete User';
    const description = 'Are you sure you want to delete this user?';
    const waitDesciption = 'User is processing...';
    const _deleteMessage = `User has been processed`;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title, description, waitDesciption },
      width: '440px'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.userService.deleteUser(this.userForm.value.user._id).pipe(
          map(res => {
            this.sharedService.openSuccessDialog(res.message, '')
            setTimeout(() => {
              location.reload();
            }, 1000)
          }), catchError(err => {
            console.log(err.error.message);
            throw err;
          })).subscribe();
      }
    });

  }
}
