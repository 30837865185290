import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { OrderService, SharedService } from "../../../core";
import { ReasonPopUpComponent } from "../../admin/reason-pop-up/reason-pop-up.component";
// import { PIRAMAL_LOGO } from 'src/app/shared/constants';

@Component({
  selector: "app-view-details",
  templateUrl: "./view-details.component.html",
  styleUrls: ["./view-details.component.scss"],
})
export class ViewDetailsComponent implements OnInit {
  orderStatusForm: FormGroup;
  token: any;
  data: any;
  idType: any;
  dobFormat: string;
  joinFormat: string;
  dateOfBirth: any;
  entity: string;
  orderType: string;
  getSingleOrderData: any = [];
  cardDetails = [
    {
      id: 1,
      type: "Business",
      path: "../../../assets/PEL_logo.jpg",
      address:
        "Piramal Ananta, Agastya Corporate Park, Opp. Fire Brigade, Kamani Junction, LBS Marg, Kurla (West), Mumbai-400 070.",
      url: "www.piramal.com",
      name: "Pirmal Enterprises Limited",
      email: "id.cards@piramal.com",
      tel: "+91 227158 3200",
    },
    {
      id: 2,
      type: "Business",
      path: "../../../assets/PCHFL_logo.jpg",
      address:
        "Unit No 601, 6th Floor, Amiti Building, Agastya Coroporate Park, Kamani Junction, Opp. Fire Station, LBS Marg, Kurla (West), Mumbai - 400 070.",
      url: "www.piramalfinance.com",
      name: "Piramal Capital & Housing Finance Limited",
      email: "id.cards@piramal.com",
      tel: "+91 22 7158 3200",
    },
    {
      id: 3,
      type: "Business",
      path: "../../../assets/PCSPL_logo.jpg",
      address:
        "Piramal Tower Annexe, 4th Floor, Ganpatrao Kadam Marg, Lower Parel, Mumbai 400 013.",
      url: "www.piramal.com",
      name: "Piramal Corporate Services Private Limited",
      email: "",
      tel: "+91 22 7158 3200",
    },
    {
      id: 4,
      type: "Business",
      path: "../../../assets/PFSS_logo.jpg",
      address:
        "Floor 4, 2 Peninsula, Corporated Park, Ganpatrao Kadam Marg, Lower Parel, Mumbai 400013.",
      url: "www.pfss.in",
      name: "Piramal Finance Sales and Service Private Limited",
      email: "id.cards@piramal.com",
      tel: "+91 22 7158 3200",
    },
    {
      id: 5,
      type: "Business",
      path: "../../../assets/PTS_logo.jpg",
      address:
        "Piramal Trusteeship Annexe, 4th Floor, Ganpatrao Kadam Marg, Lower Parel, Mumbai 400013.",
      url: "www.piramal.com",
      name: "Piramal Trusteeship Services Private Limited",
      email: "",
      tel: "+91 22 7158 3200",
      fax: "+91 22 2490 2363",
    },
  ];

  BusinessCardDetails = [
    {
      id: 1,
      type: "Business",
      path: "../../../assets/PEL_logo.jpg",
      address:
        "Piramal Ananta, Agastya Corporate Park, Opp. Fire Brigade, Kamani Junction,LBS Marg, Kurla (West), Mumbai-400 070",
      url: "piramal.com",
      name: "Pirmal Enterprises Limited",
    },
    {
      id: 2,
      type: "Business",
      path: "../../../assets/PiramalFinanceLogo.png",
      address:
        "Unit No 601, 6th Floor, Amiti Building, Agastya Coroporate Park, Kamani Junction, Opp. Fire Station,\nLBS Marg, Kurla (West), Mumbai - 400 070.",
      url: "www.piramalfinance.com",
      name: "Piramal Capital & Housing Finance Limited",
    },
    {
      id: 3,
      type: "Business",
      path: "../../../assets/PiramalFinanceLogo.png",
      address:
        "Piramal Tower Annexe, 4th Floor, Ganpatrao Kadam Marg, Lower Parel, Mumbai 400 013",
      url: "www.piramal.com",
      name: "Piramal Corporate Services Private Limited",
    },
    {
      id: 4,
      type: "Business",
      path: "../../../assets/PiramalFinanceLogo.png",
      address:
        "Floor 4, 2 Peninsula, Corporated Park, Ganpatrao Kadam Marg, Lower Parel, Mumbai 400013",
      url: "www.piramalfinance.com",
      name: "Piramal Finance Sales and Service Private Limited",
    },
    {
      id: 5,
      type: "Business",
      path: "../../../assets/PiramalFinanceLogo.png",
      address:
        "Piramal Trusteeship Annexe, 4th Floor, Ganpatrao Kadam Marg, Lower Prel, Mumbai 400013",
      url: "www.piramal.com",
      name: "Piramal Trusteeship Services Private Limited",
    },
  ];

  getBusinessCardData: any = [];
  //cardDetails = { id:1, type: "Piramal Finance Sales and Service Private Limited", path:"../../../assets/piramal finance logo.png",address:"Floor 4, 2 Peninsula Corporate Park, Ganpatrao Kadam Marg, Lower Parel, Mumbai - 400013",url: 'https://www.piramalfinance.com', name: 'Piramal Finance Sales and Service Private Limited' }
  orderStatus: Object[] = [
    { value: "upderProcessing", name: "Under Processing" },
    { value: "dispatched", name: "Dispatched" },
    { value: "cancelled", name: "Cancelled" },
  ];
  imageDataUrl: string;
  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}
  entityName = this.getSingleOrderData.legalEntity;
  cType = this.getSingleOrderData.cardType;
  ngOnInit(): void {
    //this.data = this.route.snapshot.url[this.route.snapshot.url.length - 1]?.path;

    this.route.paramMap.subscribe((params) => {
      this.data = params.get("id");
      this.idType = params.get("type");
    });
    this.initForm();
    this.getSingleOrder();
  }

  initForm() {
    this.orderStatusForm = this.fb.group({
      id: [this.data],
      awbNumber: ["", Validators.required],
      dispatchDetails: [""],
      challanNumber: ["", Validators.required],
      orderStatus: ["", Validators.required],
    });
  }

  loadOrderHistory() {
    this.orderService.getBusinessCardOrders().subscribe(
      (response) => {
        this.getBusinessCardData = response["data"];

        // this.orderStatusForm.patchValue(response.data.order)
        // const { orderStatus } = response.data.order
      },
      (error) => {}
    );
  }
  updateStatus(
    action: string,
    reason: string,
    orderUniqueID: string,
    CardGroupId: string
  ) {
    const selectedOrders = [];
    if (action === "approved") {
      if (this.idType === "ID") {
        selectedOrders.push({
          orderUniqueID: orderUniqueID,
          orderCardGroupId: CardGroupId,
        });
        let dataObj = {
          orders: selectedOrders,
          status: action,
          reason: reason,
        };
        this.orderService.approveRejectIdCardOrders(dataObj).subscribe(
          (response) => {
            this.loadOrderHistory();
            this.toastr.success("Status Updated Successfully!", "Success");
            window.location.reload();
          },
          (error) => {
            this.toastr.error("Error while Uploading", "Error");
          }
        );
      } else {
        selectedOrders.push({
          orderUniqueID: orderUniqueID,
          businessCardGroupID: CardGroupId,
        });
        let dataObj = {
          orders: selectedOrders,
          status: action,
          reason: reason,
        };
        this.orderService.approveRejectBusinessCardOrders(dataObj).subscribe(
          (response) => {
            this.loadOrderHistory();
            this.toastr.success("Status Updated Successfully!", "Success");
            window.location.reload();
          },
          (error) => {
            this.toastr.error("Error while Uploading", "Error");
          }
        );
      }
    } else if (action === "rejected") {
      selectedOrders.push({
        orderUniqueID: orderUniqueID,
        orderCardGroupId: CardGroupId,
      });
      const dialogRef = this.dialog.open(ReasonPopUpComponent, {
        width: "500px",
        disableClose: true,
        autoFocus: false,
        data: {},
      });
      dialogRef.componentInstance.reasonSubmitted.subscribe(
        (reason: string) => {
          // Call the reject order API here and pass the reason
          this.rejectOrder(selectedOrders, reason);
        }
      );
    }
  }
  rejectOrder(selectedOrders: string[], reason: string) {
    if (this.idType === "ID") {
      let dataObj = {
        orders: selectedOrders,
        status: "rejected",
        reason: reason,
      };
      this.orderService.approveRejectIdCardOrders(dataObj).subscribe(
        (response) => {
          this.loadOrderHistory();
          this.toastr.success("Status Updated Successfully!", "Success");
          window.location.reload();
        },
        (error) => {
          this.toastr.error("Error while Uploading", "Error");
        }
      );
    } else {
      let dataObj = {
        orders: selectedOrders,
        status: "rejected",
        reason: reason,
      };
      this.orderService.approveRejectBusinessCardOrders(dataObj).subscribe(
        (response) => {
          this.loadOrderHistory();
          this.toastr.success("Status Updated Successfully!", "Success");
          window.location.reload();
        },
        (error) => {
          this.toastr.error("Error while Uploading", "Error");
        }
      );
    }
  }
  getSingleOrder() {
    if (this.idType === "ID") {
      this.orderService.getSingleIdCardOrder(this.data).subscribe(
        (response) => {
          this.getSingleOrderData = response.data.order;
          this.imageDataUrl = this.getSingleOrderData.zipFileURL;
          // this.extractImage(this.getSingleOrderData.zipFileURL);
          this.orderStatusForm.patchValue(response.data.order);
          const { orderStatus } = response.data.order;
          this.disableorderForm(orderStatus);
          let dateOfBirth = this.getSingleOrderData.dateOfBirth;
          this.dobFormat = dateOfBirth.split(" ")[0];
          let dateOfJoin = this.getSingleOrderData.dateOfJoin;
          this.joinFormat = dateOfJoin.split(" ")[0];
          //alert(this.getSingleOrderData);
        },
        (error) => {}
      );
    } else {
      this.orderService.getSingleBusinessCardOrder(this.data).subscribe(
        (response) => {
          this.getSingleOrderData = response.data;
          this.orderStatusForm.patchValue(response.data);
          this.imageDataUrl = this.getSingleOrderData.zipFileURL;
          const { orderStatus } = response.data;
          this.disableorderForm(orderStatus);
          // this.extractImage(response.data.imageFile);
          //alert(this.getSingleOrderData);
        },
        (error) => {}
      );
    }
  }
  PEL = ["pel", "piramal enterprises limited"];
  PCHFL = [
    "pchfl",
    "piramal capital and housing finance limited",
    "piramal capital and housing finance",
  ];
  PCSPL = [
    "pcspl",
    "piramal corporate services private limited",
    "piramal corporate services",
  ];
  PFSS = [
    "pfss",
    "piramal finance sales and service private limited",
    "piramal finance sales and service",
  ];
  PTS = [
    "pts",
    "piramal trusteeship services private limited",
    "piramal trusteeship services",
  ];
  getCardDetailsByEntity(entityName, cardType) {
    let i;
    if (entityName !== null) {
      const entityNameLowerCase = entityName.toLowerCase().trim(); // Convert input to lowercase for case-insensitive comparison

      if (this.PEL.includes(entityNameLowerCase)) {
        i = 0;
      } else if (this.PCHFL.includes(entityNameLowerCase)) {
        i = 1;
      } else if (this.PCSPL.includes(entityNameLowerCase)) {
        i = 2;
      } else if (this.PFSS.includes(entityNameLowerCase)) {
        i = 3;
      } else if (this.PTS.includes(entityNameLowerCase)) {
        i = 4;
      } else {
        // Set a default value for cases other than the known entities.
        i = 0;
      }
    } else {
      i = 0;
    }
    if (this.idType == "ID") {
      return this.cardDetails[i];
    }
  }

  getCardDetailsByCardType(entity) {
    let i;
    if (entity !== null) {
      const cardTypeLowerCase = entity.toLowerCase().trim(); // Convert input to lowercase for case-insensitive comparison

      if (this.PEL.includes(cardTypeLowerCase)) {
        i = 0;
      } else if (this.PCHFL.includes(cardTypeLowerCase)) {
        i = 1;
      } else if (this.PCSPL.includes(cardTypeLowerCase)) {
        i = 2;
      } else if (this.PFSS.includes(cardTypeLowerCase)) {
        i = 3;
      } else if (this.PTS.includes(cardTypeLowerCase)) {
        i = 4;
      } else {
        // Set a default value for cases other than the known entities.
        i = 0;
      }
    } else {
      i = 0;
    }

    return this.BusinessCardDetails[i];
  }

  // extractImage(imageBase64: string) {
  //   this.imageDataUrl = `data:image/png;base64,${imageBase64}`;
  // }
  disableorderForm(orderStatus) {
    if (orderStatus == "dispatched" || orderStatus == "cancelled") {
      this.orderStatusForm.disable();
    }
  }

  updateOrder() {
    if (this.orderStatusForm.invalid) {
      this.orderStatusForm.markAllAsTouched();
      return;
    }

    this.orderService
      .updateOrderStatus(this.orderStatusForm.value)
      .subscribe((res) => {
        this.sharedService.openSuccessDialog(res.message, "");
        const orderStatus = this.orderStatusForm.controls.orderStatus.value;
        this.disableorderForm(orderStatus);
      });
  }

  confirmReceipt() {
    this.orderService.getOrderAcknow(this.data).subscribe(
      (response) => {
        this.sharedService.openSuccessDialog(response.message, "");
        setTimeout(() => {
          location.reload();
        });
      },
      (error) => {}
    );
  }
}
