<!-- <div class="d-flex">
    <mat-toolbar class="position-absolute" color="primary">
        <mat-toolbar-row class="justify-content-end">
            <button [routerLink]="['/login']" mat-button>Logout</button>
        </mat-toolbar-row>
    </mat-toolbar>
</div>

<br> -->

<div class="mainDiv">
  <div class="d-flex align-items-center order-card">
    <div class="container mb-5">
      <button mat-raised-button (click)="navigateOrderHistory()" *ngIf="!showUser">
        Order History
      </button>

      <div class="row justify-content-end mb-5"></div>
      <div class="container">
        <div class="tabButtons">
          <button class="btn btn-outline-primary ml-0" [class.active]="activeTab === 1" (click)="openTab(1)">
            Order Single ID Card
          </button>
          <button class="btn btn-outline-primary ml-0" [class.active]="activeTab === 2" (click)="openTab(2)">
            Order ID Cards
          </button>
          <button class="btn btn-outline-primary" [class.active]="activeTab === 3" (click)="openTab(3)">
            Order Business Cards
          </button>
        </div>

        <div class="mt-2 tab-section p-1" style="min-height: 40rem;">
          <div class="center-form" *ngIf="activeTab === 1">
            <form [formGroup]="singleIdOrder" (ngSubmit)="onSubmit()">
              <!-- <label>Sr. No.</label>
                      <input formControlName="srNo" type="number"> -->

              <label>Employee Name</label>
              <input formControlName="employeeName" type="text" />
              <p>{{ employeeName }}</p>

              <label>Employee Code</label>
              <input formControlName="employeeCode" type="text" />

              <label>Blood Group</label>
              <input formControlName="bloodGroup" type="text" />

              <label>Date of Birth</label>
              <input formControlName="dateOfBirth" type="date" />

              <label>Emergency Contact</label>
              <input formControlName="emergencyContact" type="tel" />

              <!-- <label>Date of Join</label>
              <input formControlName="dateOfJoin" type="date">

              <label>Office Location</label>
              <input formControlName="officeLocation" type="text">

              <label>Location Code</label>
              <input formControlName="locationCode" type="text">

              <label>Business Unit</label>
              <input formControlName="businessUnit" type="text">

              <label>Legal Entity</label>
              <input formControlName="legalEntity" type="text">

              <label>Zone</label>
              <input formControlName="zone" type="text"> -->

              <div class="form-group " style="width: 70%; margin: 1rem 0 2rem 0;">
                <div style="display: flex; gap: 1rem;">

                  <label for="profile-image">Upload Profile Image</label>
                  <p class="warnSize">Max 50MB.</p>
                </div>

                <div class="input-group mb-3">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input form-control" id="profile-image" accept="image/*"
                      (change)="fileChangeImage($event, 'file')" name="file" />
                    <label class="custom-file-label" for="profile-image">
                      {{ profileImageFileName || "Choose file" }}
                    </label>
                  </div>
                  <!-- <button type="upload" class="btn btn-primary" style="margin: 0rem 0 0 1rem;">Upload
                    Image</button> -->
                </div>
              </div>

              <div class="btn-div">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          <div *ngIf="activeTab === 2" class="justify-content-center d-flex py-5 row">
            <form [formGroup]="orderIdForm" (ngSubmit)="upload('ID')">
              <div class="form-group m-5">
                <label matBadgeColor="warn" for="id-card-excel">Upload Excel File (IDCard)
                </label>

                <div class="input-group mb-3">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input form-control" id="id-card-excel" accept=".xlsx"
                      (change)="fileChange($event, 'idCardExcel')" name="excel" />
                    <label class="custom-file-label" for="id-card-excel">{{
                      idCardExcelFileName || "Choose file"
                      }}</label>
                  </div>
                  &nbsp;
                  <p class="warnSize">Max 50MB.</p>
                </div>
              </div>
              <div class="form-group m-5">
                <label for="id-card-zip">Upload Zip File for Images (IDCard)</label>
                <div class="input-group mb-3">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input form-control" id="id-card-zip" accept=".zip"
                      (change)="fileChange($event, 'idCardZip')" name="zip" />
                    <label class="custom-file-label" for="id-card-zip">{{
                      idCardZipFileName || "Choose file"
                      }}</label>
                  </div>
                  &nbsp;
                  <p class="warnSize">Max 50MB.</p>
                </div>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
          <div *ngIf="activeTab === 3" class="justify-content-center d-flex py-5 row">
            <form [formGroup]="orderBusinessCardForm" (ngSubmit)="upload('Business')">
              <div class="form-group m-5">
                <label for="business-card-excel">Upload Excel File (BusinessCard)</label>
                <div class="input-group mb-5">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input form-control" id="business-card-excel" accept=".xlsx"
                      (change)="fileChange($event, 'businessCardExcel')" name="excel" />
                    <label class="custom-file-label" for="business-card-excel">{{ businessCardExcelFileName || "Choose
                      file" }}</label>
                  </div>
                  &nbsp;
                  <p class="warnSize">Max 50MB.</p>
                </div>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- <div>Excel Upload Component</div> -->
    </div>
    <div class="card-body">
      <h5>Instructions:</h5>
      <ol class="list-group list-group-numbered">
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            1. Refresh the page if orders in not being placed.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            2. Make sure to format the date in Excel file as
            <i>"dd/mm/yyyy ([$-en-IN,1]dd/mm/yyyy;@)"</i>.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            3. Make sure Zip file contains Images for all orders and named as
            per Employee Code.
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start"
          style="background-color: #ffa500; color: aliceblue">
          <div class="ms-2 me-auto">
            You Can Download MIS for ID & Business Card by clicking "Export MIS"
            Button at the top.
          </div>
        </li>
      </ol>
    </div>
  </div>
</div>

<!-- <mat-toolbar class="position-absolute" color="primary">
    <mat-toolbar-row class="justify-content-between">
        <span>Copyright © <a href="https://www.parasprint.in/"> ParasPrint</a></span>
    </mat-toolbar-row>
</mat-toolbar> -->
