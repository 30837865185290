<!doctype html>
<html lang="en">

<head>
  <!-- Required meta tagss -->
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">

  <!-- Bootstrap CSS -->
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We" crossorigin="anonymous">

  <title>ParasPrint</title>
  <link rel="stylesheet" href="home.component.scss">
  <link rel="shortcut icon" href="../../assets/favicon-paras.png" type="image/x-icon">
  <!-- bootstrap icon  -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css">

  <!-- AOS Animation  -->
  <link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css" />


</head>

<body>
  <!-- top bar  -->

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-11 mx-2">
        <div class="row  my-3 top-bar">
          <div class="col-md-8 col-11 mail">
            <i class="bi bi-envelope"><span>&nbsp;&nbsp;INFO@PARASSOLUTIONS.IN&nbsp;</span></i>
            <i class="bi bi-telephone"> <span> &nbsp;+91 &nbsp;7977065435 , &nbsp;7718924816</span></i>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- nav-bar  -->

  <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
    <div class="container-fluid">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../../assets/header-logo.png" alt="" class="img-fluid">
        </a>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto mx-5">
          <li class="nav-item">
            <a class="nav-link active text-primary" aria-current="page" href="home.component.html">HOME</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">WHO&nbsp;WE&nbsp;ARE</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">SERVICES</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">PRODUCTS</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="#">CONTACT&nbsp;US</a>
          </li>

          <button type="button" class="btn btn-primary" onclick="location.href='#'">LOGIN</button>

        </ul>
      </div>
    </div>
  </nav>


  <!-- printing services  -->

  <div class="container-fluid">

    <div class="row">
      <div class="col-md-12 col-11 printing-services py-5">
        <h1>ALL PRINTING SERVICES - <br> PARAS PRINT MUMBAI</h1>
        <p>We at Paras Print are Best Printing Company to make budget Printing Products like <br> Business/Visiting
          card,
          Pamphlets, Calendar, Diary, Book Binding, Envelope, Banners, Leaflets, <br> Passbook, Account Opening Form,
          Slip Book and Carton Box etc for you at reasonably <br> cheaper price.</p>
        <p>We also provide Offset Printing Services like Posters, Annual Reports, Pocket Folders and also <br> Printing
          services like design and marketing materials for Conferences, Trade shows, <br> Exhibitions, Events and
          Product
          launches etc.</p>
        <P>We deliver products all over India.</P>
        <button type="button" class="btn btn-primary" onclick="location.href='#'">LOGIN</button>
      </div>
    </div>
  </div>

  <!-- facilities  -->

  <div class="container-fluid facilities py-5 ">
    <div class="row justify-content-center my-5 mx-5">
      <div class="col-md-3"></div>
      <div class="col-md-9 col-11">
        <div class="row">

          <div class="col-md-6 col-11 my-5">
            <div class="row">
              <div class="col-md-2 icon">
                <i class="bi bi-display"></i>
              </div>
              <div class="col-md-8 facilities-info">
                <h5>Graphic Designer</h5>
                <p>High quality design <br> for all your printing <br> Products using Latest <br> Technology.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-11 my-5">
            <div class="row">
              <div class="col-md-2 icon">
                <i class="bi bi-pencil"></i>
              </div>
              <div class="col-md-8 facilities-info">
                <h5>Production</h5>
                <p>Digital and Offset printing <br> Production.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-11 my-5">
            <div class="row">
              <div class="col-md-2 icon">
                <i class="bi bi-truck"></i>
              </div>
              <div class="col-md-8 facilities-info">
                <h5> Delivery</h5>
                <p>All over India On-time Delivery <br> with Standardize process for <br> Dispatch and Tracking.</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-11 my-5">
            <div class="row">
              <div class="col-md-2 icon">
                <i class="bi bi-briefcase"></i>
              </div>
              <div class="col-md-8 facilities-info">
                <h5>Branding</h5>
                <p>Helping to create brand <br> merchandise, latest trends, <br> Consultancy services.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- various options  -->

  <div class="container-fluid py-5">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="row text-center">
          <div class="col-md-12 options">
            <a>ALL</a>
            <a>BOOKLETS</a>
            <a>BROCHURES</a>
            <a>BUSINESS CARDS</a>
            <a>CALENDER</a>
            <a>DIARY</a>
            <a>ENEVLOPES </a>
          </div>
          <div class="col-md-12 options">
            <a>FILES</a>
            <a>FORMS</a>
            <a>LEATHER</a>
            <a>PACKING</a>
            <a>PHARMA</a>
            <a>THERMAL ROLL</a>
          </div>
        </div>


        <div class="row options-pic">
          <div class="col-md-4 col-11 my-2">
            <a><img src="../../assets/calendar.jpg" alt="" class="img-fluid"
                style="height: 200px; width: 330px;"></a>
          </div>
          <div class="col-md-4 col-11 my-2">
            <a><img src="../../assets/diary.jpg" alt="" class="img-fluid" style="height: 200px;"></a>
          </div>
          <div class="col-md-4 col-11 my-2">
            <a><img src="../../assets/business cards.jpg" alt="" class="img-fluid" style="height: 200px;"></a>
          </div>
          <div class="col-md-4 col-11 my-2">
            <a><img src="../../assets/roll.jpeg" alt="" class="img-fluid"></a>
          </div>
          <div class="col-md-4 col-11 my-2">
            <a><img src="../../assets/book.jpg" alt="" class="img-fluid" style="height: 275px;"></a>
          </div>
          <div class="col-md-4 col-11 my-2">
            <a><img
                src="../../assets/custom-logo-brand-name-labels.jpg"
                alt="" class="img-fluid" style="height: 275px;" width="300px"></a>
          </div>
          <div class="col-md-4 col-11 my-2">
            <a><img src="../../assets/files.jpg" alt="" class="img-fluid"></a>
          </div>
          <div class="col-md-4 col-11 my-2">
            <a><img src="../../assets/boxes.jpg" alt="" class="img-fluid"></a>
          </div>
          <div class="col-md-4 col-11 my-2">
            <a><img src="../../assets/american-bank-application-form-1-638.jpg" alt="" class="img-fluid"></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- pharma block  -->

  <div class="container-fluid pharma-block">
    <div class="row justify-content-center">
      <div class="col-md-10 col-11">
        <div class="row justify-content-center">
          <div class="col-md-6 col-11 pharma-pic ">
            <img src="../../assets/machine.jpg" alt="" class="img-fluid" style="height: 400px;">
          </div>
          <div class=" col-md-5 col-11 pharma-info">
            <h3>WHY CHOOSE US</h3>
            <p>High end perfection is achieved through advanced process and dedicated customer service. </p>
            <ul>
              <li> To render service to customers that will exceed their expectations</li>
              <li>No compromise on quality</li>
              <li> Integrate most modern print production technology to achieve perfection and excellence</li>
              <li> Keep high standard in the overall process from planning to delivery</li>
              <li> Professional customer service with high end responsibility</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- working progress -->

  <div class="container-fluid my-5 ">
    <div class="row justify-content-center">
      <div class="col-md-11 col-11 text-center my-5 working-progress">
        <h2>WORKING PROCESS</h2>
      </div>
      <div class="col-md-11 col-11 text-center my-4">
        <img src="../../assets/manufacture_2.jpg" alt="" class="img-fluid">
      </div>
    </div>
  </div>




  <!-- footer  -->


  <div class="container-fluid contact-us my-5">
    <div class="row justify-content-center">
      <div class="col-md-10 col-11 my-5">
        <div class="row text-center text-white t">

          <div class="col-md-4 text-dark">
            <h1><i class="bi bi-phone"></i></h1>
            <p>+91 7977065435 <br> +91 7718924816</p>
          </div>

          <div class="col-md-4 text-dark">
            <h1><i class="bi bi-envelope"></i></h1>
            <p>info@parassolutions.in</p>
          </div>

          <div class="col-md-4 text-dark">
            <h1><i class="bi bi-geo-alt"></i></h1>
            <p>Gala No. 11, Bipin Shopping Center <br> Gaondevi Rd., Milind Nagar, <br> Bhandup(W), Mumbai- 400078</p>
          </div>
        </div>

        <div class="row pt-5  justify-content-center">
          <div class="col-md-6 col-11 copyright text-dark">
            <p>© Copyright 2023 Paras Print </p>
          </div>
          <div class="col-md-5 col-11 foot-links text-end">
            <a href="#" class="facebook px-3"><i class="bi bi-facebook"></i></a>
            <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
            <a href="#" class="linkedin px-3"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>

      </div>
    </div>
  </div>










  <!-- Option 1: Bootstrap Bundle with Popper -->
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-U1DAWAznBHeqEIlVSCgzq+c9gqGAJn5c/t99JyeKa9xxaYpSvHU5awsuZVVFIhvj"
    crossorigin="anonymous"></script>

  <!-- AOS  -->
  <script src="https://unpkg.com/aos@next/dist/aos.js"></script>
  <script>
    AOS.init();
  </script>



</body>

</html>