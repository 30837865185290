import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { OrderService } from 'src/app/core';
import { FormData } from '../models/form.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dispatch-dialog',
  templateUrl: './dispatch-dialog.component.html',
  styleUrls: ['./dispatch-dialog.component.scss']
})
export class DispatchDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DispatchDialogComponent>,
    private orderService: OrderService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  orderType = this.data.orderType;
  orderId = this.data.id;
  formData: FormData = {
    challanNumber: '',
    awbNumber: '',
    deliveryStatus: '',
    dispatchDate: '',
    courier: '',
    weight: null,
    deliveryDate: '',
    idLanyardHolderCost:50,
    
  };

  ngOnInit(): void {}

  adjustDateToTimeZone(date: Date): Date {
    const offsetMs = date.getTimezoneOffset() * 60 * 1000;
    return new Date(date.getTime() - offsetMs);
  }

  submitForm(form: NgForm) {
    // Perform desired actions with form data, e.g., send to server
    if (form.valid) {
      const dispatchDate = this.adjustDateToTimeZone(new Date(form.value.dispatchDate));
      const deliveryDate = this.adjustDateToTimeZone(new Date(form.value.deliveryDate));

      this.formData = {
        challanNumber: form.value.challanNo,
        awbNumber: form.value.awbNo,
        deliveryStatus: form.value.deliveryStatus,
        dispatchDate: dispatchDate.toISOString(),
        courier: form.value.courier,
        weight: form.value.weight,
        deliveryDate: deliveryDate.toISOString(),
        idLanyardHolderCost: form.value.idLanyardHolderCost,
      };

      this.orderService
        .updateDispatchDetails(this.formData, this.orderType, this.orderId)
        .subscribe(
          (res) => {
            this.toastr.success('Details Updated Successfully!', 'Success');
          },
          (error) => {
            // this.toastr.error(error, 'Error');
          }
        );
    }

    // Close the popup
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }
}
