<div class="position-relative">
	<img src="../../../assets/media/cancel.svg" class="cancel-mat-dialog" alt="cancel" (click)="onNoClick()">
	<h5 class="dg-bold">{{data?.title}}</h5>
</div>
<div mat-dialog-content>
	<div class="row">
		<div class="col-12 py-2">
			<div class="dg-minibold m-0">
				{{viewLoading ? data?.waitDesciption : data?.description}}
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12 pt-4 pb-1 text-center">
			<button mat-raised-button color="primary" class="mx-2" (click)="onYesClick()">Yes</button>
			<button mat-raised-button class="mx-2" (click)="onNoClick()">No</button>
		</div>
	</div>
</div>