import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ErrorSuccessDialogComponent } from '../../../partials/error-success-dialog/error-success-dialog.component';
import { API_URL } from '../../../app-constant';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from '../excel.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  getAllOrders = new BehaviorSubject(false);
  getAllOrders$ = this.getAllOrders.asObservable()

  constructor(
    private http: HttpClient,
    private excelService: ExcelService,
    private dialog: MatDialog,
    public dialogRefError: MatDialogRef<ErrorSuccessDialogComponent>,
    public dialogRefSuccess: MatDialogRef<ErrorSuccessDialogComponent>,
  ) { }

  getUserDetailsFromStorage(): Observable<any> {
    const details = JSON.parse(sessionStorage.getItem('UserDetails'));
    if (details) {
      return of(details);
    }
  }

  export(fromDate: string, toDate: string, cardType: string,isChecked: boolean,): Observable<any> {
    const queryParams = `?from=${fromDate}&to=${toDate}&getExternalMis=${isChecked}`; // Remove the extra quotes
    

    let url: string;
    if (cardType === 'IDcard') {
      url = `${API_URL}/order/id-card/msi-download${queryParams}`;
    } else {
      url = `${API_URL}/order/business-card/msi-download${queryParams}`;
    }

    return this.http.get(url, { responseType: 'arraybuffer' })
      .pipe(
        map((res) => {
          return res;
        }),
        tap(
          data => {
            const filenamePrefix = cardType === 'IDcard' ? 'MIS_ID_CARD' : 'MIS_BUSSINESS_CARD';
            this.excelService.saveAsExcelFile(data, `${filenamePrefix}_${Date.now()}`);
          },
          error => console.log(error),
        ),
        catchError(err => {
          return of(null);
        })
      );
  }

  downloadSticker(groupID) {
    this.http.get(`${API_URL}/order/id-card/sticker-download/${groupID}`, { responseType: 'arraybuffer' })
      .subscribe((response: ArrayBuffer) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Sticker.docx';
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }

  openErrorDialog(msg, type) {
    if (Object.keys(this.dialogRefError).length) {
      this.dialogRefError.close()
    }
    this.dialogRefError = this.dialog.open(ErrorSuccessDialogComponent, {
      data: { errorMessage: msg, type: type },
      width: '350px',
      panelClass: 'error-modal',
      // disableClose: true
    })
  }

  openSuccessDialog(msg, type) {
    if (Object.keys(this.dialogRefSuccess).length) {
      this.dialogRefSuccess.close()
    }
    this.dialogRefSuccess = this.dialog.open(ErrorSuccessDialogComponent, {
      data: { successMessage: msg, type: type },
      width: '350px',
      panelClass: 'success-modal',
    })
  }
}
