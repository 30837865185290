import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import {
  AuthService,
  OrderService,
  RegexValidators,
  SharedService,
  UserService,
} from "../../core";

@Component({
  selector: "order-card",
  templateUrl: "./order-card.component.html",
  styleUrls: ["./order-card.component.scss"],
})
export class OrderCardComponent implements OnInit {
  public orderCardForm: FormGroup;
  orderIdForm: FormGroup;
  singleIdOrder: FormGroup;
  orderBusinessCardForm: FormGroup;
  idCardExcelFileName: string;
  idCardZipFileName: string;
  businessCardExcelFileName: string;
  fullName: any;
  getAllOrdersData: any;
  totalOrderCount: any;
  selectedType = "Piramal Enterprises Limited";
  public branchData: Array<Object> = [];
  public branchCodeList: Array<string> = [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
  ];
  // public orderList: Array<string> = ["100", "200", "300"];
  public orderList: Array<string> = ["200"];
  public cardDetails;
  public cardTypeList = [
    {
      id: 1,
      type: "Piramal Finance Sales and Service Private Limited",
      path: "../../../assets/piramal finance logo.png",
      address:
        "Floor 4, 2 Peninsula Corporate Park, Ganpatrao Kadam Marg, Lower Parel, Mumbai - 400013",
      url: "https://www.piramalfinance.com",
      name: "Piramal Finance Sales and Service Private Limited",
    },
    {
      id: 2,
      type: "Piramal Enterprises Limited",
      path: "../../../assets/piramal-vector-logo.png",
      address:
        "Piramal Ananta, Agastya Corporate Park, Opp. Fire Brigade, Kamani Junction, LBS Marg, Kurla(West), Mumbai - 400070",
      url: "https://www.piramal.com",
      name: "Piramal Enterprises Limited",
    },
    {
      id: 3,
      type: "Piramal Capital & Housing Finance Limited",
      path: "../../../assets/piramal finance logo.png",
      address:
        "Unit No-601, 6th Floor, Amiti Building, Agastya Corporate Park, Opp. Fire Brigade, Kamani Junction, LBS Marg, Kurla(West), Mumbai - 400070",
      url: "https://www.piramalfinance.com",
      name: "Piramal Capital & Housing Finance Limited",
    },
  ];
  public selectedFiles?: FileList;
  public selectedFileNames: string[] = [];
  public previews: string[] = [];
  uploadedFiles: string[] = [];
  profileImageFileName: string;
  profileImageBase64: string | ArrayBuffer;
  public isDisplayPreview: Boolean = false;
  token: any;
  public branchCode;
  public costCenter;
  public branchName;
  public cardType;
  public firstName;
  public lastName;
  public designationOne;
  public designation2;
  public addressLineOne;
  public addressLine2;
  public city;
  public state;
  public pincode;
  public mobile;
  public phone;
  public email;
  public orderQuantity;
  public remarks;
  userDetails: any;
  userBranch: any;
  role: any;
  showUser: boolean;
  users: any;
  get data() {
    return this.orderCardForm.controls;
  }

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    public userService: UserService,
    public formBuilder?: FormBuilder,
    public router?: Router,
    public orderService?: OrderService,
    public authService?: AuthService,
    public sharedService?: SharedService,
    public ref?: ChangeDetectorRef,
    public route?: ActivatedRoute
  ) {
    this.orderIdForm = this.formBuilder.group({
      excel: [""],
      zip: [""],
    });

    this.orderBusinessCardForm = this.formBuilder.group({
      excel: [""],
    });

    this.sharedService.getUserDetailsFromStorage().subscribe((user) => {
      this.userDetails = user.userObject;
      this.userBranch = user.branchDetails;
      this.role = user.role;
    });
  }

  ngOnInit() {
    this.fullName = JSON.parse(
      sessionStorage.getItem("UserDetails")
    ).userObject.fullName;
    this.intiForm();
    this.route.queryParams.subscribe((res) => {
      if (res && res.type == "adduser") {
        this.showUser = true;
        this.orderCardForm.controls.customerId.setValidators([
          Validators.required,
        ]);
        this.orderCardForm.controls.customerId.updateValueAndValidity();
        this.orderCardForm.controls.addressLineOne.patchValue(
          this.orderCardForm.controls.addressLineTwo.value
        );
        this.orderCardForm.controls.addressLineOne.disable();
        this.getAllUsers();
      }
    });
    this.singleIdOrder = this.formBuilder.group({
      // srNo: 1,
      employeeName: [null, Validators.required],
      employeeCode: [null, Validators.required],
      bloodGroup: [null, Validators.required],
      dateOfBirth: [null, Validators.required],
      emergencyContact: [null, Validators.required],
      // dateOfJoin: [null, Validators.required],
      // officeLocation: [null, Validators.required],
      // locationCode: [null, Validators.required],
      // businessUnit: [null, Validators.required],
      // legalEntity: [null, Validators.required],
      // sharedBy:  [this.fullName, Validators.required],
      // sharedDate: [new Date().toISOString().slice(0, 10), Validators.required],
      // zone: [null, Validators.required],
      file: [null, Validators.required],
    });

    // this.getAllOrders();
  }

  intiForm() {
    this.orderCardForm = this.formBuilder.group({
      fullName: ["", [Validators.required]],
      designationOne: ["", [Validators.required]],
      team: ["", [Validators.required]],
      cardType: ["", [Validators.required]],
      addressLineOne: ["", [Validators.required]],
      addressLineTwo: [
        "Floor 4, 2 Peninsula Corporate Park, Ganpatrao Kadam Marg, Lower Parel, Mumbai - 400013",
        [Validators.required],
      ],
      mobileNumber: [
        "",
        [Validators.required, Validators.pattern(RegexValidators.mobileNumber)],
      ],
      email: [
        "",
        [Validators.required, Validators.pattern(RegexValidators.email)],
      ],
      orderQuantity: ["200", [Validators.required]],
      remarks: [""],
      customerId: [""],
    });

    this.orderCardForm.controls.addressLineTwo.disable();
    this.orderCardForm.patchValue({ customerId: this.userDetails._id });
  }

  // orderIdForm = new FormGroup({
  //   excel: new FormControl('', [Validators.required]),
  //   zip: new FormControl('', [Validators.required])
  // });

  // orderBusinessCardForm = new FormGroup({
  //   excel: new FormControl('', [Validators.required]),
  // });

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.orderCardForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  patchValue() {
    this.orderCardForm.patchValue({
      mobileNumber: this.userDetails.mobileNumber,
      email: this.userDetails.email,
      fullName: this.userDetails.fullName,
      designationOne: this.userDetails.designation,
      customerId: this.userDetails._id,
    });
    if (this.userBranch) {
      this.orderCardForm.patchValue({
        branchId: this.userBranch._id,
        addressLineOne: this.userBranch.addressLineOne,
        branchCode: this.userBranch.branchCode,
        city: this.userBranch.city,
        state: this.userBranch.state,
        pincode: this.userBranch.pincode,
      });

      // this.orderCardForm.controls.branchId.disable()
      this.orderCardForm.controls.addressLineOne.disable();
      this.orderCardForm.controls.city.disable();
      this.orderCardForm.controls.state.disable();
      this.orderCardForm.controls.pincode.disable();
    }
    // this.orderCardForm.controls.mobileNumber.disable()
    this.orderCardForm.controls.email.disable();
    this.orderCardForm.controls.fullName.disable();
    this.orderCardForm.controls.designationOne.disable();
  }

  activeTab = 1;

  openTab(tabNumber: number) {
    this.activeTab = tabNumber;
  }

  onTypeChange(card) {
    this.cardDetails = this.cardTypeList.filter(
      (data) => data.type === card
    )[0];
  }

  getAllUsers() {
    this.userService.getAllUsers().subscribe(
      (response) => {
        this.users = response["data"]["user"];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  selectUser(event) {
    this.orderCardForm.patchValue({
      // userName: event.value.userName,
      fullName: event.value.fullName,
      branchId: event.value.branchId,
      email: event.value.email,
      mobileNumber: event.value.mobileNumber,
      phoneNumber: event.value.phoneNumber,
      role: event.value.role,
      designation: event.value.designation,
    });
  }

  getAllOrders() {
    this.orderService.getAllOrders().subscribe(
      (response) => {
        this.getAllOrdersData = JSON.stringify(response["data"]["order"]);
        this.totalOrderCount = JSON.stringify(response["data"]["count"]);
      },
      (error) => {
        if (error.statusText === "Unauthorized" || "Forbidden") {
          this.router.navigate(["/auth/login"]);
        }
      }
    );
  }

  //onClick of previewCard
  onPreviewCardClick() {
    this.isDisplayPreview = true;
    this.ref.detectChanges();
  }
  downloadFormat() {
    const fileUrl = "/assets/myfile.txt"; // Replace with your file URL
    const fileName = "myfile.txt"; // Replace with your file name

    saveAs(fileUrl, fileName);
  }
  fileChangeImage(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.singleIdOrder.patchValue({
        file: file,
      });
      this.profileImageFileName = file.name;
    }
  }

  // on submit click of form
  onSubmit() {
    if (this.singleIdOrder.invalid) {
      return;
    }

    const formData = new FormData();
    Object.keys(this.singleIdOrder.controls).forEach((key) => {
      formData.append(key, this.singleIdOrder.get(key).value);
    });

    this.orderService.placeSingleIdOrder(formData).subscribe(
      (response) => {
        this.sharedService.openSuccessDialog("Order Placed Successfully", "");
        this.isDisplayPreview = false;
        setTimeout(() => {
          location.reload();
        }, 1000);
      },
      (error) => {
        setTimeout(() => {
          location.reload();
        }, 1000);
      }
    );
    if (this.orderCardForm.invalid) {
      this.orderCardForm.markAllAsTouched();
      return;
    }

    let data = this.orderCardForm.getRawValue();
    if (this.showUser) {
      this.orderService.placeOrderByAdmin(data).subscribe(
        (response) => {
          this.sharedService.openSuccessDialog("Order Placed Successfully", "");
          this.isDisplayPreview = false;
          setTimeout(() => {
            location.reload();
          }, 1000);
          //modal call to show success msg
          // this.router.navigate(["/login"]);
        },
        (error) => {
          setTimeout(() => {
            location.reload();
          }, 1000);
          //modal call to show err msg
        }
      );
    } else {
      this.orderService.placeOrder(data).subscribe(
        (response) => {
          this.sharedService.openSuccessDialog("Order Placed Successfully", "");
          this.isDisplayPreview = false;
          setTimeout(() => {
            location.reload();
          }, 1000);
          //modal call to show success msg
          // this.router.navigate(["/login"]);
        },
        (error) => {
          //modal call to show err msg
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      );
    }
  }

  selectBranchCode() {
    let branch = this.branchData.filter(
      (ele) => ele["_id"] === this.data.branchId.value
    );
    this.data.addressLineOne.patchValue(branch[0]["addressLineOne"]);
    this.data.state.patchValue(branch[0]["state"]);
    this.data.city.patchValue(branch[0]["city"]);
    this.data.pincode.patchValue(branch[0]["pincode"]);
    this.data.addressLineOne.disable();
    this.orderCardForm.controls.city.disable();
    this.orderCardForm.controls.state.disable();
    this.orderCardForm.controls.pincode.disable();
  }

  navigateOrderHistory() {
    this.router.navigate(["/orderhistoy"]);
  }

  selectFiles(event: any): void {
    this.selectedFileNames = [];
    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);

        this.selectedFileNames.push(this.selectedFiles[i].name);
      }
    }
  }

  fileChange(event: any, fileType: string) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (fileType === "idCardExcel") {
        this.idCardExcelFileName = file.name;
        this.orderIdForm.get("excel").setValue(file);
        this.updateInputValue(event.target, ""); // Reset the value of the file input element
      } else if (fileType === "idCardZip") {
        this.idCardZipFileName = file.name;
        this.orderIdForm.get("zip").setValue(file);
        this.updateInputValue(event.target, ""); // Reset the value of the file input element
      } else if (fileType === "businessCardExcel") {
        this.businessCardExcelFileName = file.name;
        this.orderBusinessCardForm.get("excel").setValue(file);
        this.updateInputValue(event.target, ""); // Reset the value of the file input element
      }
    }
  }

  updateInputValue(inputElement: HTMLInputElement, value: string) {
    const inputClone = inputElement.cloneNode(true) as HTMLInputElement;
    inputClone.value = value;
    inputElement.parentNode.replaceChild(inputClone, inputElement);
  }

  // fileChange(event) {
  //     for (var i = 0; i < event.target.files.length; i++) {
  //       this.uploadedFiles.push(event.target.files[i]);
  //   }
  //   console.log("up files",this.uploadedFiles);
  // }

  // upload(type){
  //   let formData = new FormData();
  //   for(var i=0; i< this.uploadedFiles.length; i++){
  //     formData.append("ID Card",this.uploadedFiles[i],);
  //   }
  //   this.uploadedFiles = [];
  //   this.http.post(`${API_URL}/order/uploadExcel`,formData,type).subscribe(res => {
  //     console.log("res",res);
  //   })
  // }
  upload(formType: string) {
    // Handle file upload logic here
    // Access the uploaded files based on the formType
    let requestData = {};
    if (formType === "ID") {
      const idCardExcelFile = this.orderIdForm.get("excel").value;
      const idCardZipFile = this.orderIdForm.get("zip").value;

      // Check the file size
      if (idCardZipFile && idCardZipFile.size > 50 * 1024 * 1024) {
        this.toastr.error("File size exceeds the limit of 50MB.", "Error");
        return; // Stop further execution
      }

      // Check the file type
      if (idCardZipFile && !idCardZipFile.name.endsWith(".zip")) {
        this.toastr.error(
          "Invalid file type. Please upload a ZIP file.",
          "Error"
        );
        return; // Stop further execution
      }

      const formData: FormData = new FormData();
      formData.append("files", idCardExcelFile);
      formData.append("files", idCardZipFile);
      this.orderService.uploadFile(formData, formType).subscribe(
        (response) => {
          // Handle the response from the API
          // Additional logic here
          this.toastr.success(
            "File Uploaded Successfully! Order Id ",
            "Success"
          );
          this.idCardExcelFileName = "";
          this.idCardZipFileName = "";
          this.orderIdForm.get("excel").reset(); // Reset excel file control
          this.orderIdForm.get("zip").reset(); // Reset zip file control
          window.location.reload(); // Reload the page
        },
        (error) => {
          // Handle any errors that occur during the API call
          this.toastr.error("Error while Uploading!", "Error");
          this.idCardExcelFileName = "";
          this.idCardZipFileName = "";
          this.orderIdForm.get("excel").reset(); // Reset excel file control
          this.orderIdForm.get("zip").reset(); // Reset zip file control
          window.location.reload();
        }
      );
      // Handle IDCard file uploads
    } else if (formType === "Business") {
      const businessCardExcelFile =
        this.orderBusinessCardForm.get("excel").value;
      const formData: FormData = new FormData();
      formData.append("file", businessCardExcelFile);
      this.orderService.uploadFile(formData, formType).subscribe(
        (response) => {
          // Handle the response from the API
          // Additional logic here
          this.toastr.success("File Uploaded Successfully!", "Success");
          this.businessCardExcelFileName = "";
          window.location.reload();
        },
        (error) => {
          // Handle any errors that occur during the API call
          this.toastr.error("Error while Uploading!", "Error");
          this.businessCardExcelFileName = "";
          window.location.reload();
        }
      );
    }
  }
}
