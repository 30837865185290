import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../core';

@Component({
  selector: 'app-popup-dialog',
  template: `
  <div class="popup-container">
  <h4>Select Order Date Range</h4>
  <mat-form-field>
    <input matInput readonly [matDatepicker]="fromDatepicker" placeholder="From Date" [(ngModel)]="fromDate">
    <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
    <mat-datepicker #fromDatepicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input matInput readonly [matDatepicker]="toDatepicker" placeholder="To Date" [(ngModel)]="toDate">
    <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
    <mat-datepicker #toDatepicker></mat-datepicker>
  </mat-form-field>

  <!-- Checkbox input for including checkbox value, only if cardType is IDcard -->
  <mat-checkbox *ngIf="cardType === 'IDcard'" [(ngModel)]="isChecked">Include Pre-portal data</mat-checkbox>

  <div class="button-container">
  <button mat-button (click)="closePopup()">Close</button>
    <button mat-button (click)="onDownload()" [disabled]="!fromDate || !toDate">Download</button>
  </div>
</div>

  `,
  styleUrls: ['./popup-dialog.component.scss'], // You can define the CSS class in a separate file for better styling
})
export class PopupDialogComponent {
  fromDate: Date;
  toDate: Date;
  cardType: any;
  isChecked: boolean = false; // Initialize with false (unchecked)

  constructor(
    public dialogRef: MatDialogRef<PopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private sharedService: SharedService,
  ) {
    this.cardType = data.cardType;
  }

  onDownload() {
    const fromDateFormatted = this.datePipe.transform(this.fromDate, 'dd-MM-yyyy');
    const toDateFormatted = this.datePipe.transform(this.toDate, 'dd-MM-yyyy');
    
    // Pass isChecked, cardType, fromDateFormatted, and toDateFormatted to export()
    this.sharedService.export(fromDateFormatted, toDateFormatted, this.cardType, this.isChecked).subscribe();
    
    this.dialogRef.close();
  }

  closePopup() {
    this.dialogRef.close();
  }
}
