import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { API_URL } from "../../../app-constant";
import { SharedService } from "../shared/shared.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(private http: HttpClient, private sharedService: SharedService) {}
  placeSingleIdOrder(data): Observable<any> {
    return this.http.post(`${API_URL}/order/id-card/single`, data).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }
  //! Upload Image service - uncomment this later

  // uploadImage(file: File): Observable<string> {
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   return this.http.post<any>(`${API_URL}/upload/image`, formData).pipe(
  //     map((response) => {
  //       // Assuming the API returns a string representing the URL of the uploaded image
  //       return response.imageUrl;
  //     }),
  //     catchError((error) => {
  //       // Handle error if any
  //       console.error("Error uploading image:", error);
  //       throw error;
  //     })
  //   );
  // }

  getAllOrders(action?): Observable<any> {
    const reqParams: any = {};
    if (action) {
      reqParams.status = action;
    }
    return this.http
      .get(`${API_URL}/order/business-card`, { params: reqParams })
      .pipe(
        map((res) => res),
        catchError((err) => {
          if (err.error.message)
            this.sharedService.openErrorDialog(err.error.message, "");
          throw err;
        })
      );
  }

  getIDCardOrders(action = "ready-for-review"): Observable<any> {
    const reqParams: any = {};
    if (action) {
      reqParams.status = action;
    }
    return this.http
      .get(`${API_URL}/order/id-card`, { params: reqParams })
      .pipe(
        map((res) => res),
        catchError((err) => {
          if (err.error.message)
            this.sharedService.openErrorDialog(err.error.message, "");
          throw err;
        })
      );
  }

  getBusinessCardOrders(action = "ready-for-review"): Observable<any> {
    const reqParams: any = {};
    if (action) {
      reqParams.status = action;
    }
    return this.http
      .get(`${API_URL}/order/business-card`, { params: reqParams })
      .pipe(
        map((res) => res),
        catchError((err) => {
          if (err.error.message)
            this.sharedService.openErrorDialog(err.error.message, "");
          throw err;
        })
      );
  }

  getSingleOrder(id): Observable<any> {
    return this.http.get(`${API_URL}/order/single-order/${id}`).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }

  getSingleIdCardOrder(id): Observable<any> {
    return this.http.get(`${API_URL}/order/single-id-card-order/${id}`).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }

  getSingleBusinessCardOrder(id): Observable<any> {
    return this.http
      .get(`${API_URL}/order/single-business-card-order/${id}`)
      .pipe(
        map((res) => res),
        catchError((err) => {
          if (err.error.message)
            this.sharedService.openErrorDialog(err.error.message, "");
          throw err;
        })
      );
  }

  placeOrder(data): Observable<any> {
    return this.http.post(`${API_URL}/order`, data).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }

  placeOrderByAdmin(data): Observable<any> {
    return this.http.post(`${API_URL}/order/order-by-admin`, data).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }

  updateStaus(data): Observable<any> {
    return this.http.post(`${API_URL}/order/update-status`, data).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }

  updateOrderStatus(data): Observable<any> {
    return this.http.post(`${API_URL}/order/update-order-delivery`, data).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }

  getOrderAcknow(id): Observable<any> {
    return this.http.get(`${API_URL}/order/order-acknow?orderId=${id}`).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }

  uploadFile(file, formType): Observable<any> {
    if (formType === "ID") {
      return this.http.post(`${API_URL}/order/id-card`, file).pipe(
        map((res) => res),
        catchError((err) => {
          if (err.error.message)
            this.sharedService.openErrorDialog(err.error.message, "");
          throw err;
        })
      );
    } else if (formType === "Business") {
      return this.http.post(`${API_URL}/order/business-card`, file).pipe(
        map((res) => res),
        catchError((err) => {
          if (err.error.message)
            this.sharedService.openErrorDialog(err.error.message, "");
          throw err;
        })
      );
    }
  }

  uploadDesign(file, orderType, orderId): Observable<any> {
    if (orderType === "ID") {
      return this.http
        .post(`${API_URL}/order/id-card/upload-design/${orderId}`, file)
        .pipe(
          map((res) => res),
          catchError((err) => {
            if (err.error.message)
              this.sharedService.openErrorDialog(err.error.message, "");
            throw err;
          })
        );
    } else if (orderType !== "ID") {
      return this.http
        .post(`${API_URL}/order/business-card/upload-design/${orderId}`, file)
        .pipe(
          map((res) => res),
          catchError((err) => {
            if (err.error.message)
              this.sharedService.openErrorDialog(err.error.message, "");
            throw err;
          })
        );
    }
  }

  getUploadedFiles(): Observable<any> {
    return this.http.get(`${API_URL}/order/business-card`).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }

  // approveRejectIdOrders():Observable<any>{
  //   return this.http.put(`${API_URL}/order/id-card/update-status`,data).pipe(
  //     map(res => res),
  //     catchError)(err => {
  //       if (err.error.message)
  //         this.sharedService.openErrorDialog(err.error.message, '')
  //       throw err
  //     })
  //   )
  // }

  approveRejectIdCardOrders(data): Observable<any> {
    return this.http.put(`${API_URL}/order/id-card/update-status`, data).pipe(
      map((res) => res),
      catchError((err) => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, "");
        throw err;
      })
    );
  }

  approveRejectBusinessCardOrders(data): Observable<any> {
    return this.http
      .put(`${API_URL}/order/business-card/update-status`, data)
      .pipe(
        map((res) => res),
        catchError((err) => {
          if (err.error.message)
            this.sharedService.openErrorDialog(err.error.message, "");
          throw err;
        })
      );
  }

  updateDispatchDetails(data, orderType, orderId): Observable<any> {
    if (orderType === "ID") {
      return this.http
        .put(`${API_URL}/order/id-card/dispatch/${orderId}`, data)
        .pipe(
          map((res) => res),
          catchError((err) => {
            if (err.error.message)
              this.sharedService.openErrorDialog(err.error.message, "");
            throw err;
          })
        );
    } else {
      return this.http
        .put(`${API_URL}/order/business-card/dispatch/${orderId}`, data)
        .pipe(
          map((res) => res),
          catchError((err) => {
            if (err.error.message)
              this.sharedService.openErrorDialog(err.error.message, "");
            throw err;
          })
        );
    }
  }
}
