<mat-card>
    <h3 class="text-center">Enter OTP</h3>
    <p class="text-center mt-4">OTP has been sent to your registered email ID.</p>
    <p class="text-center">OTP is valid for 10 mins</p>
    <form [formGroup]="OtpForm">
      <mat-form-field class="w-100">
        <!-- <input type="password" matInput placeholder="Enter the OTP" formControlName="otp"> -->
        <mat-label>Enter the OTP</mat-label>
        <input matInput [type]="hideOTP ? 'password' : 'text'" formControlName="otp">
        <button mat-icon-button matSuffix (click)="hideOTP = !hideOTP" [attr.aria-label]="'Hide otp'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{hideOTP ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="OtpForm.controls.otp.errors?.required">OTP is required</mat-error>
      </mat-form-field>
      <div class="mt-1 text-center">
        <button class="btn btn-block mb-1" mat-raised-button color="primary"
          (click)="openChangePasswordDialog()">Submit</button>
        <span>Didn't get OTP? <span class="resend-btn">Resend</span></span>
      </div>
      <div class="mt-4 text-right">
        <a class="ml-2" mat-stroked-button (click)="cancelMenu()">Cancel</a>
      </div>
    </form>
  </mat-card>
