import { Component } from "@angular/core";

@Component({
  selector: "app-download-billing",
  templateUrl: "./download-billing.component.html",
  styleUrls: ["./download-billing.component.scss"],
})
export class DownloadBillingComponent {
  selectedMonth: string;
  selectedYear: number;
  months = ["June 2023", "July 2023", "August 2023", "September 2023", "October 2023", "November 2023"];
  years = [2023]; // You can add more years as needed

  downloadBilling() {
    const { selectedMonth, selectedYear } = this;

    // Generate the filename based on the selected month and year
    const filename = `${selectedMonth}_${selectedYear}_billing.xlsx`;

    // Construct the URL based on the selected month
    let url;
    switch (selectedMonth) {
      case "June 2023":
        url = `https://s3.ap-south-1.amazonaws.com/test-files-piramal.parassolutions.in/Billing/June+2023/MIS+__ID+Card+Billing__June+2023.xlsx`;
        break;
      case "July 2023":
        url = `https://s3.ap-south-1.amazonaws.com/test-files-piramal.parassolutions.in/Billing/July+2023/MIS+__ID+Card+Billing__July+2023.xlsx`;
        break;
      case "August 2023":
        url = `https://s3.ap-south-1.amazonaws.com/test-files-piramal.parassolutions.in/Billing/August+2023/MIS+__ID+Card+Billing__August+2023.xlsx`;
        break;
      case "September 2023":
        url = `https://s3.ap-south-1.amazonaws.com/test-files-piramal.parassolutions.in/Billing/September+2023/MIS+__ID+Card+Billing__September+2023.xlsx`;
        break;
      case "October 2023":
        url = `https://s3.ap-south-1.amazonaws.com/test-files-piramal.parassolutions.in/Billing/October+2023/MIS+__ID+Card+Billing__October+2023.xlsx`;
        break;
      case "November 2023":
        url = `https://s3.ap-south-1.amazonaws.com/test-files-piramal.parassolutions.in/Billing/November+2023/MIS+__ID+Card+Billing__November+2023.xlsx`;
        break;

      // Add cases for other months if needed
      default:
        console.error("Invalid month selected");
        return;
    }
    window.open(url, "_blank");

    // Download the file
  }
}
