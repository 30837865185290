<mat-toolbar class="position-fixed top-0 width-100 z-index" color="primary">
  <mat-toolbar-row class="justify-content-between">
    <div class="button-group">
      <button (click)="goBack()" mat-button class="header-logo">
        <mat-icon class="header-logo-icon">arrow_back</mat-icon>
      </button>
      <button (click)="redirectToHome()" mat-button class="header-logo">
        <mat-icon class="header-logo-icon">home</mat-icon>
      </button>
    </div>
    <!-- <button mat-button [routerLink]="''" [disabled]="!canGoBack()">Back</button> -->
    <!-- Show .user-info div on desktop -->
    <div class="user-info desktop-view">
      {{ this.fullName }} is logged in as
      <span class="user-role">{{ this.role }}</span>
    </div>
    <!-- Show .user-infoMobile div on mobile -->

    <!-- Apply scrollable-buttons class conditionally -->
    <div
      [ngClass]="{
        'header-buttons': true,
        'scrollable-buttons': isScreenWidthSmall
      }"
    >
      <button
        (click)="export('IDcard')"
        *ngIf="this.role != 'user'"
        mat-button
        class="header-button"
      >
        Export MIS(ID Card)
      </button>
      <button
        (click)="export('BussinessCrad')"
        *ngIf="this.role != 'user'"
        mat-button
        class="header-button"
      >
        Export MIS(Business Card)
      </button>
      <button (click)="logout()" mat-button class="header-button">
        Logout
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
