<div class="container" *ngIf="data?.errorMessage || data?.successMessage">
    <div class="row justify-content-center">
        <img *ngIf="data?.errorMessage" class="image"
            [src]="data?.type ? 'assets/media/KYC-Web.svg' : 'assets/media/failure.svg'" alt="failure-logo">
        <img *ngIf="data?.successMessage" class="image" src="assets/media/success.svg" alt="success-logo">
    </div>
    <div class="row justify-content-center my-3">
        <h6 class="text-center dg-bold mb-0 error-message">{{data?.errorMessage}}</h6>
        <h6 class="text-center dg-bold mb-0 success-message">{{data?.successMessage}}</h6>
    </div>
    <div class="row justify-content-center text-center">
        <div class="col-12">
            <app-button [type]="'button'" [value]="'Okay'" (action)="close(data?.type)"></app-button>
        </div>
    </div>
</div>
<div class="row text-left" *ngIf="serverError">
    <div class="col-12">
        <mat-error [ngClass]="myClass">{{serverError}}</mat-error>
    </div>
</div>
<div class="row text-left" *ngIf="serverSuccess">
    <div class="col-12 text-success">
        {{serverSuccess}}
    </div>
</div>