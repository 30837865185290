import { AfterViewInit, Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataSource } from '@angular/cdk/table';
import { Router } from '@angular/router';
import { OrderService, SharedService } from '../../core/';
import { MatDialog } from '@angular/material/dialog';
import { ReasonPopUpComponent } from './reason-pop-up/reason-pop-up.component';
import { Subscription } from 'rxjs';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = [
    'select',
    'orderId',
    'date',
    'status',
    'details',
    'approveBtn',
    'rejectBtn',
    'status'
  ];
  ELEMENT_DATA = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  selection = new SelectionModel(true, []);
  subscription: Subscription[] = [];
  token: any;
  orderType: string = 'ID';
  uniqueId : string = "";
  accordionItems = [];
  activeIndex = null;
  activeButton: string = 'ID';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  reason = null;
  searchValue = '';
  role:any = "";
  selectedAction: string = 'Approve';
  excelUrl:string = "";
  status:string = "";
  excelUrlIDCard:string = "";
  excelUrlBusinessCard:string = "";
  zipUrl:string = "";
  designUrl: string = "";
  isDesignUrlPresent:boolean;
  ordersList:any;
  expandedPanels: { [key: string]: boolean } = {};

  constructor(
    private router: Router,
    private orderService: OrderService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private http: HttpClient,
    private route: ActivatedRoute,
    
  ) {
    const orders = this.sharedService.getAllOrders$.subscribe((res) => {
      if (res) {
        this.getAllOrders();
        this.sharedService.getAllOrders.next(false);
      }
    });
    this.subscription.push(orders);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.orderType = params['type'] || 'ID'; // Set default tab to ID if no type query parameter
      this.loadOrderHistory();
      this.setActiveButton(this.orderType); // Set activeButton based on orderType
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getUserDetails(){
    this.sharedService.getUserDetailsFromStorage().subscribe(user => {
    this.role = user.role
  })
  }

  

  toggleAccordion(index: number) {
    this.activeIndex = this.activeIndex === index ? null : index;
  }

  isActive(index: number) {
    return this.activeIndex === index;
  }

  setActiveButton(buttonName: string): void {
    this.activeButton = buttonName;
  }

  loadOrderHistory(status?) {
    if (this.orderType === 'ID') {
      this.accordionItems = [];
      this.orderService.getIDCardOrders(status).subscribe(
        (response) => {
          this.accordionItems = response['data'];
          this.ELEMENT_DATA = response['data'];
          this.dataSource.data = this.ELEMENT_DATA;
          this.uniqueId = response['data'][0]['orders'][0]['orderCardGroupId'];
          const localGrpId = localStorage.getItem('grpId');
      this.toggleExpansion(localGrpId); // Pass localGrpId to toggleExpansion
        },
        (error) => {
          if (error.statusText === 'Unauthorized' || error.statusText === 'Forbidden') {
            this.router.navigate(['/auth/login']);
          }
        }
      );
    } else {
      this.orderService.getBusinessCardOrders(status).subscribe(
        (response) => {
          this.accordionItems = response['data'];
          this.ELEMENT_DATA = response['data'];
          this.dataSource.data = this.ELEMENT_DATA;
          this.uniqueId = response['data'][0]['orders'][0]['businessCardGroupId'];
          const localGrpId = localStorage.getItem('grpId');
      this.toggleExpansion(localGrpId); // Pass localGrpId to toggleExpansion
        },
        (error) => {
          if (error.statusText === 'Unauthorized' || error.statusText === 'Forbidden') {
            this.router.navigate(['/auth/login']);
          }
        }
      );
    }
    this.getUserDetails();
  }
  toggleExpansion(localGrpId: string) {
    this.accordionItems.forEach((item) => {
      const groupId = this.orderType === "ID" ? item._id.orderCardGroupId : item._id.businessCardGroupId;
      // Set the expanded state of each panel individually
      this.expandedPanels[groupId] = groupId === localGrpId;
    });
  }
  storeValue(value) {
    localStorage.setItem('grpId', value);
  }
  // Add this method to retrieve the expanded panel ID from localStorage on page load
  retrieveExpandedPanel() {
    const expandedPanelId = localStorage.getItem('expandedPanelId');
    if (expandedPanelId) {
      this.toggleExpansion(expandedPanelId);
    }
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  openTab(type: string) {
    this.orderType = type;
    this.loadOrderHistory();
    this.router.navigate([], { queryParams: { type: this.orderType }, queryParamsHandling: 'merge' });
  }

  openUrl(url: string): void {
    window.open(url);
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllOrdersData: any;
  totalOrderCount: any;

  getAllOrders(action?) {
    this.orderService.getAllOrders(action).subscribe(
      (response) => {
        this.getAllOrdersData = JSON.stringify(response['data']['order']);
        this.totalOrderCount = JSON.stringify(response['data']['count']);
        this.ELEMENT_DATA = response['data']['order'];
        this.dataSource.data = this.ELEMENT_DATA;
      },
      (error) => {
        if (error.statusText === 'Unauthorized' || error.statusText === 'Forbidden') {
          this.router.navigate(['/auth/login']);
        }
      }
    );
  }
  showSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
    });
  }

  downloadExcelForIDCard(orderID): void {
    this.excelUrlIDCard = this.accordionItems.flatMap((innerArray) => innerArray.orders.filter(obj => obj.orderCardGroupId === orderID))[0]['excelfileURL'];
    const fileUrl = this.excelUrlIDCard;
    const urlWithoutParams = fileUrl.split('?')[0]; // Remove query parameters from the URL
    window.open(urlWithoutParams, '_blank');
  }

  downloadExcelForBusinessCard(orderID): void {
    //this.excelUrlBusinessCard = this.accordionItems.flatMap((innerArray) => innerArray.orders.filter(obj => obj.businessCardGroupId === orderID))[0]['excelfileURL'];
    const excelUrlBusinessCard = this.accordionItems.flatMap((innerArray) => innerArray.orders.filter(obj => obj.businessCardGroupId === orderID))[0]['excelFileURL'];
    const fileUrl = excelUrlBusinessCard;
    const urlWithoutParams = fileUrl.split('?')[0]; // Remove query parameters from the URL
    window.open(urlWithoutParams, '_blank');
  }

  downloadZip(orderID): void {
    this.zipUrl = this.accordionItems.flatMap((innerArray) => innerArray.orders.filter(obj => obj.orderCardGroupId === orderID))[0]['zipFileURL'];
    const fileUrl = this.zipUrl;
    const urlWithoutParams = fileUrl.split('?')[0]; // Remove query parameters from the URL
    window.open(urlWithoutParams, '_blank');
  }
  
  downloadDesign(orderID): void {
    this.ordersList = this.accordionItems.flatMap((innerArray) => innerArray.orders.filter((obj,i) => obj.orderCardGroupId === orderID));
    this.designUrl = this.ordersList[0].designFileURL;
    this.isDesignUrlPresent = this.ordersList.every(obj => obj.hasOwnProperty('designFileURL'));
    if(this.isDesignUrlPresent && this.designUrl !== null){
      const fileUrl = this.designUrl;
      const urlWithoutParams = fileUrl.split('?')[0]; // Remove query parameters from the URL
      window.open(urlWithoutParams, '_blank');
    }
    else
    {
      this.toastr.error("No Designs Uploaded !!",'Error');
    }
    
  }
  downloadBilling(){
    this.router.navigate(['/admin/download-billing']);
  }
  viewDetails(id,type) {
    this.router.navigate(['/admin/view-details', id,type]);
  }

  addOrders() {
    this.router.navigate(['/ordercard']);
  }

  modifyUser() {
    this.router.navigate(['/admin/modify-user']);
  }

  addUser() {
    this.router.navigate(['/admin/add-user']);
  }

  deleteOrder() {}

  multipleSelection(action) {
    let selectData = this.selection.selected;
    let pendingData = selectData.filter((ele) => ele.status === 'pending');
    let id = pendingData.map((ele) => ele['_id']);
    this.approveRejectOrders(id, action);
  }

  getReason(id?) {
    const dialogRef = this.dialog.open(ReasonPopUpComponent, {
      width: '350px',
    });
    dialogRef.afterClosed().subscribe((pop) => {
      if (pop) {
        this.reason = pop;
        if (id && id.length === 1) {
          this.approveRejectOrders(id, 'rejected');
        } else {
          this.multipleSelection('rejected');
        }
      }
    });
  }

  approveRejectOrders(id, action) {
    const data = {
      id: id,
      status: action,
      reason: this.reason,
    };
    this.orderService.updateStaus(data).subscribe(
      (response) => {
        this.getAllOrders();
        this.reason = null;
        this.selection.clear();
      },
      (error) => {}
    );
  }
  toggleGroupSelection(group: any) {
    const groupSelected = !group.orders.every((order: any) => order.isSelected);
    group.orders.forEach((order: any) => (order.isSelected = groupSelected));
    for (const item of this.accordionItems) {
      for (const order of item.orders) {
        order.selected = !groupSelected;
      }
    }
  }
  isGroupSelected(group: any): boolean {
    return this.accordionItems.every((item) =>
      item.orders.every((order) => order.selected)
    );
  }

  toggleOrderSelection(order: any, group: any) {
    order.isSelected = !order.isSelected;
  }

  rejectOrder(selectedOrders: string[], reason: string) {
    if (this.orderType === "ID") {
      let dataObj = {
        orders: selectedOrders,
        status: "rejected",
        reason: reason,
      };
      this.orderService.approveRejectIdCardOrders(dataObj).subscribe(
        (response) => {
          this.loadOrderHistory();
          this.toastr.success("Status Updated Successfully!", "Success");
        },
        (error) => {
          this.toastr.error("Error while Uploading", "Error");
        }
      );
    } else {
      let dataObj = {
        orders: selectedOrders,
        status: "rejected",
        reason: reason,
      };
      this.orderService.approveRejectBusinessCardOrders(dataObj).subscribe(
        (response) => {
          this.loadOrderHistory();
          this.toastr.success("Status Updated Successfully!", "Success");
        },
        (error) => {
          this.toastr.error("Error while Uploading", "Error");
        }
      );
    }
  }

  performAction(reason) {
    const selectedOrders = [];
    for (const item of this.accordionItems) {
      for (const order of item.orders) {
        if (order.isSelected) {
          if (this.orderType === "ID") {
            selectedOrders.push({
              orderUniqueID: order._id,
              orderCardGroupId: order.orderCardGroupId,
            });
            order.action = this.selectedAction;
          } else {
            selectedOrders.push({
              orderUniqueID: order._id,
              businessCardGroupID: order.businessCardGroupId,
            });
            order.action = this.selectedAction;
          }
        }
      }
    }

    const status =
      this.selectedAction === "Approve"
        ? "approved"
        : this.selectedAction === "Reject"
        ? "rejected"
        : "completed";
    if (status === "approved") {
      if (selectedOrders.length !== 0) {
        if (this.orderType === "ID") {
          let dataObj = {
            orders: selectedOrders,
            status: status,
            reason: reason,
          };
          this.orderService.approveRejectIdCardOrders(dataObj).subscribe(
            (response) => {
              this.loadOrderHistory();
              this.toastr.success("Status Updated Successfully!", "Success");
            },
            (error) => {
              this.toastr.error("Error while Uploading", "Error");
            }
          );
        } else {
          let dataObj = {
            orders: selectedOrders,
            status: status,
            reason: reason,
          };
          this.orderService.approveRejectBusinessCardOrders(dataObj).subscribe(
            (response) => {
              this.loadOrderHistory();
              this.toastr.success("Status Updated Successfully!", "Success");
            },
            (error) => {
              this.toastr.error("Error while Uploading", "Error");
            }
          );
        }
      }
    } else if (status === "rejected") {
      const dialogRef = this.dialog.open(ReasonPopUpComponent, {
        width: "500px",
        disableClose: true,
        autoFocus: false,
        data: {},
      });

      dialogRef.componentInstance.reasonSubmitted.subscribe(
        (reason: string) => {
          // Call the reject order API here and pass the reason
          this.rejectOrder(selectedOrders, reason);
        }
      );
    }
  }
  ngOnDestroy() {
    this.subscription.map((ele) => ele.unsubscribe());
  }
}
