import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  date = new Date();
  year:any
  constructor() { }

  ngOnInit(): void {
    this.year = this.date.getFullYear();
  }

}
