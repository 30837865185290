export class RegexValidators {
    // patterns
    public static mobileNumber: string = '^[6-9][0-9]{9}$';

    public static PAN: string = '^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$';

    public static pinCode: string = '[1-9][0-9]{5}';

    public static email: string = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'

    public static aadhaarNumber: string = '^\\d{4}\\d{4}\\d{4}$'

    public static ifscCode: string = '[A-Za-z]{4}[a-zA-Z0-9]{7}'

    public static accountNumber: string = '^(?=.*\\d)(?=.*[1-9]).{3,20}$'

    public static userName: string = '^[A-Za-z\\s]*$'

    public static password: string = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,32}$'

    // public static phoneNumber: string = '^[0-9]\\d{2,4}-\\d{6,8}$'
    public static phoneNumber: string = '^[0-9]\\d{2,4}\\d{6,8}$'
}