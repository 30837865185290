import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { SharedService } from '../_services/shared/shared.service';
import { AuthService } from '../_services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReverseAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private sharedService:SharedService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn()) {
      this.sharedService.getUserDetailsFromStorage().subscribe(res => {
      if (res.role == 'admin') {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/ordercard']);
      }
    })
      return false;
    } else {
      return true;
    };
  }
}
