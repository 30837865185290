import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  date = new Date();
  year:any
  url: string;
  constructor(
    public router: Router
  ) { 
    this.router.events.subscribe(() => {
      this.url = this.router.url
		})
  }

  ngOnInit(): void {
  this.year = this.date.getFullYear();
  }

}
