import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { HeaderComponent } from './header/header.component'
import { AngularMaterialModule } from './angular-material/angular-material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorSuccessDialogComponent } from './error-success-dialog/error-success-dialog.component';
import { RouterModule } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NumberDirective } from '../core';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupDialogComponent } from '../partials/popup-dialog/popup.component'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
@NgModule({
  declarations: [
    // components
    HeaderComponent,
    ErrorSuccessDialogComponent,
    ConfirmationDialogComponent,
    NumberDirective,
    PopupDialogComponent
    
    // directives
  ],
  imports: [
    // module
    CommonModule,
    CoreModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,

  ],
  exports: [
    // module
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // components
    HeaderComponent,
    ErrorSuccessDialogComponent,
    ConfirmationDialogComponent,

    // directives
    NumberDirective
  ],
  entryComponents: [
    ErrorSuccessDialogComponent,
    ConfirmationDialogComponent,
  ], providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class PartialsModule { }
