import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-app-select-file",
  templateUrl: "./app-select-file.component.html",
  styleUrls: ["./app-select-file.component.scss"],
})
export class AppSelectFileComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
