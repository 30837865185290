import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedService } from '../shared/shared.service';
import { API_URL } from '../../../app-constant';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  getAllUsers(): Observable<any> {
    return this.http.get(`${API_URL}/user`).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    )
  }

  getAllBranches(): Observable<any> {
    return this.http.get(`${API_URL}/branch`).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    )
  }

  getAllRoles(): Observable<any> {
    return this.http.get(`${API_URL}/role`).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    )
  }

  updateUser(id, data): Observable<any> {
    return this.http.put<any>(`${API_URL}/user?id=` + `${id}`, data).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    )
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(`${API_URL}/user?id=` + `${id}`).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    )
  }
}
