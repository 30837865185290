import { Component, OnInit, Inject, Input, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-success-dialog',
  templateUrl: './error-success-dialog.component.html',
  styleUrls: ['./error-success-dialog.component.scss']
})
export class ErrorSuccessDialogComponent implements OnInit {
  @Input() serverError;
  @Input() serverSuccess;
  @Input() myClass;

  constructor(
    public dialogRef: MatDialogRef<ErrorSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const { serverError } = changes
    if (serverError && serverError.currentValue) {
      // this.clearServerError()
    }
  }

  clearServerError() {
    setTimeout(() => {
      if (this.serverError) {
        this.serverError = ''
      }
    }, 2000)
  }

  close(type) {
    switch (type) {
      case 'digi-gold-kyc':
        this.router.navigate(['/kyc/digi-gold'], { queryParams: { redirect: 'thank-you' } });
        break;
    }
    this.dialogRef.close();
  }
}
