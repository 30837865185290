<h2 mat-dialog-title>Add Dispatch Details</h2>
<form (ngSubmit)="submitForm(popupForm)" #popupForm="ngForm">
  <mat-dialog-content>
    <mat-form-field appearance="fill" style="width: 100%">
      <p>AWB No.</p>
      <input
        matInput
        type="number"
        placeholder="AWB No."
        name="awbNo"
        [(ngModel)]="formData.awbNumber"
        required
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <p>Dispatch Date</p>
      <input
        matInput
        readonly
        [matDatepicker]="datepicker1"
        placeholder="MM-DD-YYYY"
        name="dispatchDate"
        [(ngModel)]="formData.dispatchDate"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
      <mat-datepicker #datepicker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <p>Weight</p>
      <input
        matInput
        type="number"
        placeholder="Weight"
        name="weight"
        [(ngModel)]="formData.weight"
        required
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <p>Courier</p>
      <input
        matInput
        placeholder="Courier"
        name="courier"
        [(ngModel)]="formData.courier"
        required
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <p>Challan No</p>
      <input
        matInput
        placeholder="Challan No"
        name="challanNo"
        [(ngModel)]="formData.challanNumber"
        required
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <p>Delivery Status</p>
      <mat-select name="deliveryStatus" [(ngModel)]="formData.deliveryStatus" required>
        <mat-option value="Artwork Pending">Artwork Pending</mat-option>
        <mat-option value="Approval Pending">Approval Pending</mat-option>
        <mat-option value="Cancelled">Cancelled</mat-option>
        <mat-option value="Delivered">Delivered</mat-option>
        <mat-option value="Delivered At Bandra East">Delivered At Bandra East</mat-option>
        <mat-option value="Delivered At Kurla">Delivered At Kurla</mat-option>
        <mat-option value="In Transit">In Transit</mat-option>
        <mat-option value="Need Delivery Address">Need Delivery Address</mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-form-field appearance="fill" style="width: 100%">
      <p>Delivery Date</p>
      <input
        matInput
        readonly
        [matDatepicker]="datepicker2"
        placeholder="MM-DD-YYYY"
        name="deliveryDate"
        [(ngModel)]="formData.deliveryDate"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
      <mat-datepicker #datepicker2></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <p>Id, Lanyard, Holder Cost</p>
      <input
        matInput
        type="number"
        placeholder="Id, Lanyard, Holder Cost"
        name="idLanyardHolderCost"
        [(ngModel)]="formData.idLanyardHolderCost"
        required
      />
    </mat-form-field>
    <!-- Repeat the above <mat-form-field appearance="fill" style="width: 100%"> block for the remaining fields (field3 to field7) -->
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="onCancel()">Cancel</button>
    <button mat-button color="primary" type="submit" [disabled]="!popupForm.valid">Submit</button>
  </mat-dialog-actions>
</form>
