import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderCardComponent } from './pages/order-card/order-card.component';

import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { BaseComponent } from './base/base.component';
import { PartialsModule } from './partials/partials.module';
import { AngularMaterialModule } from './partials/angular-material/angular-material.module';
import { InterceptService } from './core';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ExcelFileUploaderComponent } from './pages/excel-file-uploader/excel-file-uploader.component';
import { AppSelectFileComponent } from './app-select-file/app-select-file.component';
import { UploadDialogComponent } from './pages/upload-dialog/upload-dialog.component';
//import { PopupComponent } from './shared/popup/popup.component';
import { ReasonPopUpComponent } from './pages/admin/reason-pop-up/reason-pop-up.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DispatchDialogComponent } from './pages/dispatch-dialog/dispatch-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BaseComponent,
    ExcelFileUploaderComponent,
    AppSelectFileComponent,
    UploadDialogComponent,
    DispatchDialogComponent,
    
    // PopupComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule, 
    MatRippleModule,
    HttpClientModule,
    PartialsModule,
    MatToolbarModule,
    MatExpansionModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    AngularMaterialModule,
    NgHttpLoaderModule.forRoot(),
    // HttpModule
  ],
  providers: [MatDialog,
    MatDatepickerModule,
    MatNativeDateModule,  
    InterceptService,
    DatePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }