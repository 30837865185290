import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

  OtpForm: FormGroup;
  hideOTP = true;
  hide = true;
  email: any;
  token: any;
  userRole: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initOtpForm()
    this.route.queryParams
      .subscribe(params => {
        this.email = params['email']
      })
  }
  get data() {
    return this.OtpForm.controls;
  }
  initOtpForm() {
    this.OtpForm = this.formBuilder.group({
      otp: ["", Validators.required],
    })
  }

  openChangePasswordDialog() {
    if (this.OtpForm.invalid) {
      this.OtpForm.markAllAsTouched()
      return
    }
    if (sessionStorage.getItem('referenceCode')) {
      const verifyOtpdata = {
        referenceCode: atob(sessionStorage.getItem('referenceCode')),
        otp: this.data.otp.value,
        email: this.email,
      };


      const config = {
        // url: `${API_URL}auth/verify-otp`,
        data: verifyOtpdata,
      };
      if (this.router.url.includes('otp-login')) {
        this.authService.verfiyLoginOtp(verifyOtpdata).subscribe(
          (response) => {
            sessionStorage.clear()
            sessionStorage.setItem('UserDetails', JSON.stringify(response.data))
            this.token = response["data"]["token"];
            this.userRole = response["data"]["role"];
            if (this.token) {
              if (this.userRole === 'admin') {
                this.router.navigate(["/admin"]);
              } else if(this.userRole === 'vendor') {
                this.router.navigate(["/vendor"]);
              }else{
                this.router.navigate(["/order-card"]);
              }
            }
          },
          (error) => {

            //modal call to show err msg
          }
        );
      } else {

      
      this.authService.verfiyOtp(verifyOtpdata).subscribe(
        (response) => {
          this.router.navigate(['auth/change-password']);
          // this.changePasswordComponent = true;
        },
        (error) => {

          //modal call to show err msg
        }
      );
      }
    } else {
    }
  }

  cancelMenu() {
    this.router.navigate(['/auth/login']);
  }
}
