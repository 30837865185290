import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderHistoryComponent } from '../../order-history/order-history.component';

@Component({
  selector: 'app-reason-pop-up',
  templateUrl: './reason-pop-up.component.html',
  styleUrls: ['./reason-pop-up.component.scss']
})
export class ReasonPopUpComponent implements OnInit {
  status: string;
  reason: string;

  @Output() reasonSubmitted: EventEmitter<string> = new EventEmitter<string>();

  constructor(public dialogRef: MatDialogRef<ReasonPopUpComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  submit() {
    this.reasonSubmitted.emit(this.reason);
    this.dialogRef.close();
  }
}
