import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, RegexValidators } from '../../../core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgetpasswordForm: FormGroup;
  showSpinner: boolean = false;
  hide = true;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router:Router,
  ) { }

  ngOnInit(): void {
    this.initForgotForm()
  }

  get dataForgetPassword(){
    return this.forgetpasswordForm.controls
  }
  initForgotForm(){
    this.forgetpasswordForm = this.formBuilder.group({
      emailId: ["", [Validators.required, Validators.pattern(RegexValidators.email)]],
    });
  }

  openOtpDialog() {
    // this.loadSpinner();

    if (this.forgetpasswordForm.invalid) {
      this.forgetpasswordForm.markAllAsTouched()
      // this.loginComponent = false;
      // this.forgotPasswordComponent = true;
      return;
    } 
    // else {

    const sendOtpdata = {
      email: this.forgetpasswordForm.value.emailId,
      otpFor:'forgotPassword'
    };
    // const config = {
    //   url: `${API_URL}auth/send-otp`,
    //   data: sendOtpdata,
    // };

    this.authService.sendOtp(sendOtpdata).subscribe(
      (response) => {
        this.router.navigate(['auth/otp']);
        //var sendOtpApiMsg = response['message'];
        // this.sendOtpApiRefCode = response['referenceCode'] || '';
        sessionStorage.setItem('referenceCode', btoa(response['referenceCode']))

      },
      (error) => {
        //modal call to show err msg
        // alert(`${error.statusText}: Email Id is not valid. Please try again !`);
      }
    );

    // }

  }

  cancelMenu(){
    this.router.navigate(['auth/login']);
  }

  loadSpinner() {
    this.showSpinner = true;
    setTimeout(() => {
      this.showSpinner = false;
    }, 2000);
  }
}
