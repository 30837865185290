<mat-card>
    <h3 class="text-center">Forgot password?</h3>
    <p class="text-center mt-4">Enter your Registered Email ID.</p>
    <form [formGroup]="forgetpasswordForm">
      <mat-form-field class="w-100">
        <mat-label>Email ID</mat-label>
        <input type="email" matInput placeholder="Email ID" formControlName="emailId">
        <mat-icon matSuffix>email</mat-icon>
        <mat-error *ngIf="dataForgetPassword.emailId.errors?.required">Email ID is required</mat-error>
        <mat-error *ngIf="dataForgetPassword.emailId.errors?.pattern">Invalid Email ID</mat-error>
      </mat-form-field>
      <div class="mt-4 text-center">
        <button [disabled]="showSpinner" mat-raised-button color="primary"
          (click)="openOtpDialog()">Submit</button>
        <a class="ml-2" mat-stroked-button color="primary" (click)="cancelMenu()">Cancel</a>
      </div>
    </form>
  </mat-card>
