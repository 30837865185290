import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../core';
import { MatDialog,MatDialogRef  } from '@angular/material/dialog';
import {PopupDialogComponent} from '../popup-dialog/popup.component'
import { Location } from '@angular/common'; 
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  role: any;
  fullName: any;
  cardType: any;
  isScreenWidthSmall = false;
  dialogRef: MatDialogRef<any>;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private dialog: MatDialog ,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.sharedService.getUserDetailsFromStorage().subscribe(user => {
      this.role = JSON.parse(sessionStorage.getItem('UserDetails')).role;
      this.fullName = JSON.parse(sessionStorage.getItem('UserDetails')).userObject.fullName;
    });

    this.checkScreenWidth();
  }
  canGoBack(): boolean {
    return history.state !== null;
  }
  
  // Logout
  logout() {
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }
  goBack() {
    this.location.back();
  }
  export(cardType) {
    // this.sharedService.export(cardType).subscribe();
    this.openPopup(cardType); // Call the function to open the popup
  }

  openPopup(cardType: any) {
    this.dialogRef = this.dialog.open(PopupDialogComponent, {
      width: '300px', // Adjust the width as needed
      data: { message: 'hello world', cardType: cardType } // Pass an object with the message and cardType
    });
  }

  closePopup() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }


  redirectToHome() {
    switch (this.role) {
      case 'admin':
        window.location.href = '/admin';
        break;
      case 'vendor':
        window.location.href = '/vendor';
        break;
      case 'user':
        window.location.href = '/ordercard';
        break;
      default:
        // Redirect to a default page if the role doesn't match any of the cases
        window.location.href = '/default-page';
        break;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    // Set the value based on the screen width threshold you prefer
    this.isScreenWidthSmall = window.innerWidth <= 768; // Adjust the threshold as needed
  }
}
