import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { API_URL } from "../../../app-constant";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  mobileNumber: string;
  role: string;
  designation: string;
  location: string;
  entity: string;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
      {
        fullName: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(8)]],
        confirmPassword: ["", Validators.required],
        mobileNumber: [
          "",
          [Validators.required, Validators.pattern(/^\d{10}$/)],
        ], // Pattern for 10-digit phone number
      },
      { validators: this.passwordMatchValidator }
    );
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get("password").value;
    const confirmPassword = formGroup.get("confirmPassword").value;

    if (password !== confirmPassword) {
      formGroup.get("confirmPassword").setErrors({ passwordMismatch: true });
    } else {
      formGroup.get("confirmPassword").setErrors(null);
    }
  }

  onSubmit(): void {
    if (this.registerForm.invalid) {
      return;
    }

    // Adding default values before sending the form data
    const formData = {
      ...this.registerForm.value,
      designation: "Mumbai",
      location: "Mumbai",
      entity: "xyz",
      userName: this.registerForm.value.email,
      role: "user",
    };

    this.http.post(`${API_URL}/user`, formData).subscribe(
      (response) => {
        this.toastr.success("User registered successfully.", "Success");
        this.router.navigate(["/auth/login"]);
      },
      (error) => {
        console.error("Error occurred while registering user.", error);
        this.toastr.error("Error occurred while registering user.", "Error");
      }
    );
  }
}
