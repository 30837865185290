import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {API_URL} from '../../../app-constant';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  registerForm: FormGroup;
 fullName: string;
  role: string;
  designation:string;
  email: string;
  mobileNumber: string;
  location: string;
  entity: string;
  userName: string;
  password: string;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      role: ['', Validators.required],
      designation: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', Validators.required],
      location: ['', Validators.required],
      entity: ['', Validators.required],
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.registerForm.invalid) {
      return;
    }

    const formData = {
      fullName:this.fullName,
      role: this.role,
      designation: this.designation,
      email: this.email,
      mobileNumber: this.mobileNumber,
      location: this.location,
      entity: this.entity,
      userName: this.userName,
      password: this.password
    };

    this.http.post(`${API_URL}/user`, formData)
      .subscribe(
        response => {
          this.toastr.success('User registered successfully.', 'Success',);
          window.location.reload(); // Reload the page
          // Add any additional handling or redirection logic here
        },
        error => {
          console.error('Error occurred while registering user.', error);
          this.toastr.error('Error occurred while registering user.', 'Error');
          // Add any error handling logic here
        }
      );

  //     this.http.post(`${API_URL}/user`, formData)
  // .subscribe(
  //   response => {
  //     this.toastr.success('User created successfully');
  //     // Add any additional handling or redirection logic here
  //   },
  //   error => {
  //     console.error('Error occurred while registering user.', error);
  //     // Add any error handling logic here
  //   }
  // );
  }
}
