<div class="container">
  <mat-card *ngIf="loginComponent">
    <img class="logo" src="../../../../assets/PiramalFinanceLogo.png" alt="" />
    <h3 class="text-center">Login</h3>
    <div style="display: flex; flex-direction: row-reverse;">
      <a mat-button color="primary" (click)="openRegisterDialog()">
        <u>Register/New User</u>
      </a>
    </div>
    <form [formGroup]="loginForm">
      <mat-form-field class="w-100">
        <mat-label>Enter your Email Id</mat-label>
        <input type="text" matInput placeholder="Email" formControlName="username" />
        <mat-icon matSuffix>account_circle</mat-icon>
        <mat-error *ngIf="data.username.touched && data.username.errors?.required">Email is required</mat-error>
        <mat-error *ngIf="data.username.touched && data.username.errors?.pattern">Invalid Email</mat-error>
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide" type="button">
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <mat-error *ngIf="data.password.touched && data.password.errors?.required">Password is required</mat-error>
      </mat-form-field>
      <!-- ... (existing code) ... -->

      <div class="mt-4" style="display: flex; flex-direction: column;">
        <div class="login-buttons" style="display: flex; gap: 10px;">
          <button mat-raised-button color="primary" class="ml-2" (click)="sendOtp()" style="flex-grow: 1;">
            Login with OTP
          </button>
          <button mat-raised-button color="primary" (click)="onSubmit()" style="flex-grow: 1;">
            Login
          </button>
        </div>
        <div class="links-container mt-2" style="display: flex; flex-direction: row-reverse;">
          <a mat-button color="primary" (click)="openForgotPasswordDialog()" style="margin-right: -0.3rem;">
            <u>Forgot password</u>
          </a>
        </div>
      </div>

    </form>

    <div class="alert alert-danger mt-4" role="alert" *ngIf="submitted">
      Invalid Email Address!!
    </div>

    <div class="contact-info mt-4">
      <div class="row justify-content-between">
        <div class="col-sm-6">
          <p>Email: <a href="mailto:info@parassolutions.in" style="color: rgb(53, 53, 53);">info@parassolutions.in</a>
          </p>
        </div>
        <div class="col-sm-6 text-sm-right">
          <p>Contact: <br><a href="tel:+917387875004" style="color: rgb(53, 53, 53);">+91 73878 75004</a></p>
        </div>
      </div>
    </div>
  </mat-card>
</div>
