import { Component, OnInit, ViewChild } from "@angular/core";
import { OrderService } from "../../core/_services/order/order.service";
import { SharedService } from "src/app/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { API_URL } from "../../app-constant";
import { ToastrService } from "ngx-toastr";
import { MatAccordion } from "@angular/material/expansion";
import { MatDialog } from "@angular/material/dialog";
import { saveAs } from "file-saver";
import { DispatchDialogComponent } from "../dispatch-dialog/dispatch-dialog.component";
import { ReasonPopUpComponent } from "../admin/reason-pop-up/reason-pop-up.component";
import { flatMap } from "lodash";
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: "app-order-history",
  templateUrl: "./order-history.component.html",
  styleUrls: ["./order-history.component.scss"],
})
export class OrderHistoryComponent implements OnInit {
  displayedColumns: string[] = [
    "fullName",
    "designation",
    "department",
    "deliveryAddress",
    "mobileNumber",
    "email",
    "orderQuantity",
    "createdAt",
    "updatedAt",
    "status",
  ];
  cardItemDetails;
  orderType: string = "ID";
  selectedAction: string = "Approve";
  isPopupVisible: boolean = false;
  popupReason: string = "";
  activeButton: string = "ID";
  selection: string = "";
  disptachStatus: string = "";
  status: string = "";
  excelUrl: string = "";
  zipUrl: string = "";
  designUrl: string = "";
  createdAt: string = "";
  role: any = "";
  testUrl: string = "";
  ordersList: any;
  isDesignUrlPresent: boolean;
  isPanelExpanded = false;
  OrderGrpId: any;
  accordionItems: any[] = [];
  expandedPanels: { [key: string]: boolean } = {};
  //@ViewChild('MatAccordion') MatAccordion!: MatAccordion;

  constructor(
    private orderService: OrderService,
    private sharedService: SharedService,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.orderType = params['type'] || 'ID'; // Set default tab to ID if no type query parameter
      this.loadOrderHistory();
      this.setActiveButton(this.orderType); // Set activeButton based on orderType
    
    });
  }

  loadOrderHistory(status?) {
    if (this.orderType === "ID") {
      this.orderService.getIDCardOrders(status).subscribe(
        (response) => {
          this.accordionItems = response["data"];
          //this.excelUrl = response['data'][0]['excelfileURL'];
          this.zipUrl = response["data"][0]["zipFileURL"];
          this.status = response["data"][0].orders[0]["status"];
          this.designUrl = response["data"][0]["designFileURL"];
          const localGrpId = localStorage.getItem('grpId');
      this.toggleExpansion(localGrpId); // Pass localGrpId to toggleExpansion
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (this.orderType === "Business") {
      let cardItemDetails;
      this.orderService.getBusinessCardOrders(status).subscribe(
        (response) => {
          this.accordionItems = response["data"];
          this.excelUrl = response["data"][0]["excelfileURL"];
          this.zipUrl = response["data"][0]["zipFileURL"];
          this.designUrl = response["data"][0]["designFileURL"];
          const localGrpId = localStorage.getItem('grpId');
      this.toggleExpansion(localGrpId); // Pass localGrpId to toggleExpansion
        },
        (error) => {
          console.log(error);
        }
      );
    }
    this.getUserDetails();
  }
  toggleExpansion(localGrpId: string) {
    this.accordionItems.forEach((item) => {
      const groupId = this.orderType === "ID" ? item._id.orderCardGroupId : item._id.businessCardGroupId;
      // Set the expanded state of each panel individually
      this.expandedPanels[groupId] = groupId === localGrpId;
    });
  }

  // Add this method to retrieve the expanded panel ID from localStorage on page load
  retrieveExpandedPanel() {
    const expandedPanelId = localStorage.getItem('expandedPanelId');
    if (expandedPanelId) {
      this.toggleExpansion(expandedPanelId);
    }
  }
  getUserDetails() {
    this.sharedService.getUserDetailsFromStorage().subscribe((user) => {
      this.role = user.role;
    });
  }

  openTab(type: string) {
    this.orderType = type;
    this.loadOrderHistory();
    this.router.navigate([], { queryParams: { type: this.orderType }, queryParamsHandling: 'merge' });
  }
  

  setActiveButton(buttonName: string): void {
    this.activeButton = buttonName;
  }
  storeValue(value) {
    localStorage.setItem('grpId', value);
  }
  downloadExcelForIDCard(orderID): void {
    this.excelUrl = this.accordionItems.flatMap((innerArray) =>
      innerArray.orders.filter((obj) => obj.orderCardGroupId === orderID)
    )[0]["excelfileURL"];
    const fileUrl = this.excelUrl;
    const urlWithoutParams = fileUrl.split("?")[0]; // Remove query parameters from the URL
    window.open(urlWithoutParams, "_blank");
  }

  downloadExcelForBusinessCard(orderID): void {
    //this.excelUrlBusinessCard = this.accordionItems.flatMap((innerArray) => innerArray.orders.filter(obj => obj.businessCardGroupId === orderID))[0]['excelfileURL'];
    const excelUrlBusinessCard = this.accordionItems.flatMap((innerArray) =>
      innerArray.orders.filter((obj) => obj.businessCardGroupId === orderID)
    )[0]["excelFileURL"];
    const fileUrl = excelUrlBusinessCard;
    const urlWithoutParams = fileUrl.split("?")[0]; // Remove query parameters from the URL
    window.open(urlWithoutParams, "_blank");
  }

  downloadZip(orderID): void {
    this.zipUrl = this.accordionItems.flatMap((innerArray) =>
      innerArray.orders.filter((obj) => obj.orderCardGroupId === orderID)
    )[0]["zipFileURL"];
    const fileUrl = this.zipUrl;
    const urlWithoutParams = fileUrl.split("?")[0]; // Remove query parameters from the URL
    window.open(urlWithoutParams, "_blank");
  }

  downloadDesign(orderID): void {
    this.ordersList = this.accordionItems.flatMap((innerArray) =>
      innerArray.orders.filter((obj, i) => obj.orderCardGroupId === orderID)
    );
    this.designUrl = this.ordersList[0].designFileURL;
    this.isDesignUrlPresent = this.ordersList.every((obj) =>
      obj.hasOwnProperty("designFileURL")
    );
    if (this.isDesignUrlPresent && this.designUrl !== null) {
      const fileUrl = this.designUrl;
      const urlWithoutParams = fileUrl.split("?")[0]; // Remove query parameters from the URL
      window.open(urlWithoutParams, "_blank");
    } else {
      this.toastr.error("No Designs Uploaded !!", "Error");
    }
  }

  viewDetails(id, type) {
    //this.router.navigate(['./orderhistoy/view-details',id,type]);
    this.router.navigate(["./orderhistoy/view-details", id, type]);
  }

  // viewDetails(id, _id) {
  //   {{ this.orderType === 'ID' ? this.router.navigate(['./orderhistoy/view-details', id]) :  this.router.navigate(['./orderhistoy/view-details', _id])}}

  // }

  toggleGroupSelection(group: any) {
    const groupSelected = !group.orders.every((order: any) => order.isSelected);
    group.orders.forEach((order: any) => (order.isSelected = groupSelected));
    for (const item of this.accordionItems) {
      for (const order of item.orders) {
        order.selected = !groupSelected;
      }
    }
  }
  isGroupSelected(group: any): boolean {
    return this.accordionItems.every((item) =>
      item.orders.every((order) => order.selected)
    );
  }

  toggleOrderSelection(order: any, group: any) {
    order.isSelected = !order.isSelected;
  }

  rejectOrder(selectedOrders: string[], reason: string) {
    if (this.orderType === "ID") {
      let dataObj = {
        orders: selectedOrders,
        status: "rejected",
        reason: reason,
      };
      this.orderService.approveRejectIdCardOrders(dataObj).subscribe(
        (response) => {
          this.loadOrderHistory();
          this.toastr.success("Status Updated Successfully!", "Success");
        },
        (error) => {
          this.toastr.error("Error while Uploading", "Error");
        }
      );
    } else {
      let dataObj = {
        orders: selectedOrders,
        status: "rejected",
        reason: reason,
      };
      this.orderService.approveRejectBusinessCardOrders(dataObj).subscribe(
        (response) => {
          this.loadOrderHistory();
          this.toastr.success("Status Updated Successfully!", "Success");
        },
        (error) => {
          this.toastr.error("Error while Uploading", "Error");
        }
      );
    }
  }

  performAction(reason) {
    const selectedOrders = [];
    for (const item of this.accordionItems) {
      for (const order of item.orders) {
        if (order.isSelected) {
          if (this.orderType === "ID") {
            selectedOrders.push({
              orderUniqueID: order._id,
              orderCardGroupId: order.orderCardGroupId,
            });
            order.action = this.selectedAction;
          } else {
            selectedOrders.push({
              orderUniqueID: order._id,
              businessCardGroupID: order.businessCardGroupId,
            });
            order.action = this.selectedAction;
          }
        }
      }
    }

    const status =
      this.selectedAction === "Approve"
        ? "approved"
        : this.selectedAction === "Reject"
        ? "rejected"
        : "completed";
    if (status === "approved") {
      if (selectedOrders.length !== 0) {
        if (this.orderType === "ID") {
          let dataObj = {
            orders: selectedOrders,
            status: status,
            reason: reason,
          };
          this.orderService.approveRejectIdCardOrders(dataObj).subscribe(
            (response) => {
              this.loadOrderHistory();
              this.toastr.success("Status Updated Successfully!", "Success");
            },
            (error) => {
              this.toastr.error("Error while Uploading", "Error");
            }
          );
        } else {
          let dataObj = {
            orders: selectedOrders,
            status: status,
            reason: reason,
          };
          this.orderService.approveRejectBusinessCardOrders(dataObj).subscribe(
            (response) => {
              this.loadOrderHistory();
              this.toastr.success("Status Updated Successfully!", "Success");
            },
            (error) => {
              this.toastr.error("Error while Uploading", "Error");
            }
          );
        }
      }
    } else if (status === "rejected") {
      const dialogRef = this.dialog.open(ReasonPopUpComponent, {
        width: "500px",
        disableClose: true,
        autoFocus: false,
        data: {},
      });

      dialogRef.componentInstance.reasonSubmitted.subscribe(
        (reason: string) => {
          // Call the reject order API here and pass the reason
          this.rejectOrder(selectedOrders, reason);
        }
      );
    }

    //this.MatAccordion.closeAll();

    // Send the selected orders and action to the backend for processing
  }

  showPopup(): void {
    this.isPopupVisible = true;
  }

  onOKClicked(reason: string): void {
    this.popupReason = reason;
    // Perform necessary actions with the reason
    this.isPopupVisible = false;
    this.performAction(this.popupReason);
  }

  openPopup(id) {
    const dialogRef = this.dialog.open(DispatchDialogComponent, {
      width: "400px",
      data: {
        id: id,
        orderType: this.orderType,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadOrderHistory();
        // Perform any additional actions after file upload
      }
    });
  }

  onCancelClicked(): void {
    // Handle cancel action
    this.isPopupVisible = false;
  }
}
