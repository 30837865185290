import { Component, Directive,EventEmitter,HostListener,Input,OnInit,Output } from '@angular/core';

Component({
  selector: 'app-excel-file-uploader',
  templateUrl: './excel-file-uploader.component.html',
  styleUrls: ['./excel-file-uploader.component.scss']
})
@Directive({
  selector:'[ExcelFileUploaderComponent]'
})
export class ExcelFileUploaderComponent implements OnInit {

  fileSelectOrDragAreaClass: string;

  @Input()
  readonly requiredFileType: string;

  @Input()
  readonly selectFileMessage: string;

  @Input()
  readonly fileName: string;

  @Input()
  private idPrependString: string;

  @Input()
  readonly isMultipleFileSelectionAllowed: boolean;

  fileInputClass: string;

  constructor() {}

  ngOnInit() {
    this.fileSelectOrDragAreaClass = 'file-drag-area';
    this.fileInputClass = `${this.idPrependString}-file-input`;
  }

  // Host Listener to listen drag and drop event.

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.fileSelectOrDragAreaClass = 'file-drop-area';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    this.fileSelectOrDragAreaClass = 'file-drop-area';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    this.fileSelectOrDragAreaClass = 'file-drag-area';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    this.fileSelectOrDragAreaClass = 'file-drag-area';
    event.preventDefault();
  }

  /**
   * This handles drag and drop event.
   * @param event
   */
  @HostListener('drop', ['$event']) onDrop(event: any) {
    this.fileSelectOrDragAreaClass = 'file-drag-area';
    event.preventDefault();
    event.stopPropagation();
    let files: FileList = event.dataTransfer.files;
    if (files!=null) {
      this.onFileSelected$.emit(files);
    }
  }

  /**
   * This will handle change event on file selected.
   * It will emit event based on data file or attachment file.
   * @param fileSelectEvent
   */
  onFileSelected(fileSelectEvent) {
    let files: FileList = fileSelectEvent.target.files;
    if (files!=null) {
      this.onFileSelected$.emit(files);
    }
  }

  @Output() onFileSelected$: EventEmitter<any> = new EventEmitter();

  getElementId(idAppender: string): string {
    return `${this.idPrependString}-${idAppender}`;
  }




}
