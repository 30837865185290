<div class="container order-details-table" >
    
  <!--BUSNESS CARD DESIGN STARTS-->
  <div class="aspectRatio">
  <div
    class="row justify-content-center align-items-center"
    *ngIf="idType !== 'ID'" 
  >
    <div class="col-sm-6 h-25 py-3 ">
      <mat-card class="order-card__card h-25 ">
        <div class="previewCard p-3" style="height: 18rem; margin-bottom: 20px; color: #033e66;">
          <div class="row previewCard__logo my-3" >
            <img
              class="img-size"
              src="{{ getCardDetailsByCardType(getSingleOrderData.entity).path }}"
              alt=""
              srcset=""
              style="height: 5rem;"
            />
            
          </div>
          <div class="row my-4" style="height: 4rem;">
            <div class="col-6" >
              <div class="ml-5" >
                <span  class="text-break h6" >{{
                  getSingleOrderData.employeeName
                  || "-" 
                }}</span>
                
                <p class="text-break m-0">
                  {{ getSingleOrderData.designation  || "-" }}
                </p>
                <p class="text-break m-0">
                  {{ getSingleOrderData.department  || "-" }}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="previewCard__content">
                <div class="previewCard__content--title">
                </div>
                <div
                  class="row previewCard__contet--address justify-content-center text-center"
                >
                  <div class="col-sm-11 text-left">
                    <div *ngIf="getSingleOrderData.entity !== 'PEL' && getSingleOrderData.entity !== 'piramal enterprises limited'" class="h6">
                      {{ getCardDetailsByCardType(getSingleOrderData.entity).name }}
                      <br />
                    </div>
                    
                    
                    <p class="text-break m-0" style="width: 10rem;">
                      {{ getSingleOrderData.branchAddress}}
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <div class="ml-5">
                <div  *ngIf="getCardDetailsByCardType(getSingleOrderData.entity).name == 'Pirmal Enterprises Limited'">

                  <h6  class="m-0 mat-h5">Piramal Enterprises Limited</h6>
                </div>
                <p class="m-0">+91 {{ getSingleOrderData.mobileNumber  || "-" }}</p>
                <p class="m-0">
                  {{ getSingleOrderData.email  || "-" }}
                </p>
                <p class="m-0 website-text">{{ getSingleOrderData.url }}</p>
                <p class="m-0 website-text">{{ getCardDetailsByCardType(getSingleOrderData.entity).url }}</p>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  </div>
  <!--END OF BUSINESS CAARD DESIGN-->
  <!--ID CARD DESIGN STARTS-->
  <div
    class="row justify-content-center align-items-center"
    *ngIf="idType === 'ID'"
  >
    <div class="col-sm-4 h-25 py-3">
      <div class="card" style="width: 18rem; height: 31rem;">
        <div class="card-img-top text-center mt-5">
          <img class="img-size" src="{{ getCardDetailsByEntity(getSingleOrderData.legalEntity).path }}" alt="" srcset="" />
        </div>
        
        <div class="row my-3 justify-content-center align-items-center">
          <img
            class="profile-img"
            [src]="imageDataUrl"
            onerror="src='../../../assets/red_bg_dummy.png'"
            alt=""
            srcset=""
          />
        </div>
        
        <div class="card-body mb-4 pt-0">
          <h5 class="card-title text-center">
            {{ getSingleOrderData?.employeeName || "-" }}
          </h5>
          <div class="d-flex justify-content-center">
            <table>
              <thead>
                <tr>
                  <td>Employee Id</td>
                  <td class="cell-spacing">
                    : {{ getSingleOrderData.employeeCode  || "-" }}
                  </td>
                </tr>
                <tr>
                  <td>Blood Group</td>
                  <td class="cell-spacing">
                    : {{ getSingleOrderData.bloodGroup  || "-" }}
                  </td>
                </tr>
                <tr>
                  <td>Birth Date</td>
                  <td class="cell-spacing">
                    : {{ dobFormat  || "-" }}
                  </td>
                </tr>
                <tr>
                  <td>Emergency No</td>
                  <td class="cell-spacing">
                    : {{ getSingleOrderData.emergencyContact  || "-" }}
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 h-25 py-3">
      <div class="card backface" style="width: 18rem">
        <div class="card-body">
          <h5 class="card-title bg-text instructions-text">INSTRUCTIONS</h5>
          <p class="card-text mb-1"> <!-- Added the mb-2 class here -->
            1. For Security Identification purpose wearing this card is mandatory
          </p>
          <p class="card-text mb-1"> <!-- Added the mb-2 class here -->
            2. This card must be produced on demand to the security staff or any authorised person.
          </p>
          <p class="card-text mb-1"> <!-- Added the mb-2 class here -->
            3. Loss or theft of the card must be reported immediately to the undersigned.
          </p>
          <p class="card-text mb-1"> <!-- Added the mb-2 class here -->
            4. This card not-transferable and must be surrendered on demand.
          </p>
          <div class="card-text text-right" style="padding-right: 20px;">
            <img class="sign-size" src="../../../assets/aman-sir-sign.jpg" alt="" srcset="" />
          </div>
          <p class="card-text text-right">Issuing Authority</p>
        </div>
        <div class="card-img-bottom bg-blue p-3" src="..." alt="Card image cap">
          <div class="footer-text">
            <h6>{{ getCardDetailsByEntity(getSingleOrderData.legalEntity).name}}</h6>
            <p>
              {{  getCardDetailsByEntity(getSingleOrderData.legalEntity).address }}<br>
              {{  getCardDetailsByEntity(getSingleOrderData.legalEntity).email }}<br>
              {{  getCardDetailsByEntity(getSingleOrderData.legalEntity).tel }}
              {{  getCardDetailsByEntity(getSingleOrderData.legalEntity).fax }}
              
            </p>
            <p class="website-link">{{ getCardDetailsByEntity(getSingleOrderData.legalEntity).url}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--ID CARD DESIGN ENDS-->
  <!-- Reason for rejection -->
  <div *ngIf=" !!getSingleOrderData.reason && getSingleOrderData.reason != 'no reason'"style="color: red ;">

    <strong>*Reason for rejection: </strong><u>{{getSingleOrderData.reason || "-" }}</u>
  </div>
  <!-- TABLE HEADER  FOR ID CARD STARTS-->
  <table class="table table-bordered mt-4">
    <thead class="thead-dark">
      <tr>
        <th scope="row" colspan="3">
          Order ID : {{ getSingleOrderData?.orderUniqueId  || "-" }}
        </th>
      </tr>
    </thead>
    <!-- <thead class="">
      <tr>
        <th scope="col">Product ID</th>
        <th scope="col">Ordered By</th>
        <th scope="col">QTY</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{idType || "-" }} Card</td>
        <td>
          {{ getSingleOrderData?.fullName || getSingleOrderData?.employeeName  || "-" }}
        </td>
        <td>{{ getSingleOrderData?.orderQuantity  || "-" }}</td>
      </tr>
    </tbody>
    <thead class="thead-dark">
      <tr>
        <th scope="row" colspan="3">Other Details</th>
      </tr>
    </thead> -->
    <!-- TABLE HEADER FOR ID CARD ENDS -->
    <!--BUSINESS CARD TABLE STARTS-->
    <!-- <tbody *ngIf="idType !== 'ID'"> -->
      <!-- <tr>
        <td>Total Items</td>
        <td colspan="2">1</td>
      </tr>
      <tr>
        <td>Customer Name</td>
        <td colspan="2">{{ getSingleOrderData?.employeeName }}
          
        </td>
      </tr>
      <tr>
        <td>Designation</td>
        <td colspan="2">{{ getSingleOrderData?.designation }}</td>
      </tr>
      <tr>
        <td>Division</td>
        <td colspan="2">{{ getSingleOrderData.department }}</td>
      </tr>
      <tr>
        <td>Mobile</td>
        <td colspan="2">{{ getSingleOrderData.mobileNumber }}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td colspan="2">{{ getSingleOrderData.email }}</td>
      </tr>
      <tr></tr>
      <tr>
        <td>Delivery Address</td>
        <td colspan="2">{{ getSingleOrderData.deliveryAddress }}</td>
      </tr>
      <tr>
        <td>Remarks / Dispatch Details</td>
        <td colspan="2">{{ getSingleOrderData.dispatchDetails }}</td>
      </tr>
      <tr>
        <td>Current Order Status</td>
        <td colspan="2">
          {{
            getSingleOrderData.status == "completed"
              ? "Approved"
              : getSingleOrderData.status == "pending"
              ? "Approval Pending"
              : (getSingleOrderData.status | titlecase)
          }}
        </td>
      </tr>
      <tr *ngIf="getSingleOrderData.status == 'rejected'">
        <td>Reason</td>
        <td colspan="2">{{ getSingleOrderData.reason | titlecase }}</td>
      </tr>

      <tr>
        <td>Order ID</td>
        <td colspan="2">
          {{ getSingleOrderData?.orderUniqueId }}
        </td>
      </tr>
      <tr>
        <td>Status</td>
        <td colspan="2">
          {{
            getSingleOrderData?.orderStatus
              ? (getSingleOrderData?.orderStatus | titlecase)
              : "Under Processing"
          }}
        </td>
      </tr>
      <tr>
        <td>AWB No</td>
        <td colspan="2">
          {{
            getSingleOrderData?.awbNumber ? getSingleOrderData?.awbNumber : "-"
          }}
        </td>
      </tr>
      <tr>
        <td>Challan No</td>
        <td colspan="2">
          {{
            getSingleOrderData?.challanNumber
              ? getSingleOrderData?.challanNumber
              : "-"
          }}
        </td>
      </tr>
      <tr>
        <td>Remarks/Dispatch Details</td>
        <td colspan="2">
          {{ getSingleOrderData?.remarks  || "-" }}
        </td>
      </tr> -->
      <!--BUSINESS CARD TABLE ENDS-->
      <!-- <tr
        *ngIf="
          getSingleOrderData?.orderStatus == 'dispatched' &&
          !getSingleOrderData?.acknowReceived
        "
      >
        <td colspan="3" class="text-right">
          <button mat-raised-button (click)="confirmReceipt()">
            Confirm Receipt
          </button>
        </td>
      </tr> -->
    <!-- </tbody> -->
    <!-- ID CARD TABLE STARTS -->
    <!-- <tbody *ngIf="idType === 'ID'">
      <tr>
        <td>Employee Code</td>
        <td colspan="2">{{ getSingleOrderData.employeeCode || "-" }}</td>
      </tr>
      <tr>
        <td>Employee Name</td>
        <td colspan="2">{{ getSingleOrderData.employeeName || "-"  }}</td>
      </tr>
      <tr>
        <td>Blood Group</td>
        <td colspan="2">{{ getSingleOrderData.bloodGroup  || "-" }}</td>
      </tr>
      <tr>
        <td>Date Of Birth</td>
        <td colspan="2">{{ dobFormat  || "-" }}</td>
      </tr>
      <tr>
        <td>Emergency Contact</td>
        <td colspan="2">{{ getSingleOrderData.emergencyContact  || "-" }}</td>
      </tr>
      <tr>
        <td>Date Of Join</td>
        <td colspan="2">{{ joinFormat  || "-" }}</td>
      </tr>
      <tr>
        <td>Office Location</td>
        <td colspan="2">{{ getSingleOrderData.officeLocation  || "-" }}</td>
      </tr>
      <tr>
        <td>Zone</td>
        <td colspan="2">{{ getSingleOrderData?.zone  || "-" }}</td>
      </tr>
      <tr>
        <td>Location Code</td>
        <td colspan="2">{{ getSingleOrderData?.locationCode  || "-" }}</td>
      </tr>
      <tr>
        <td>Business Unit</td>
        <td colspan="2">{{ getSingleOrderData.businessUnit || "-"  }}</td>
      </tr>
      <tr>
        <td>Legal Entity</td>
        <td colspan="2">{{ getSingleOrderData.legalEntity  || "-" }}</td>
      </tr>
    </tbody>
      <thead class="thead-dark">
        <tr>
          <th scope="row" colspan="3">Dispatch Details</th>
        </tr>
      </thead> -->
      <!-- ID CARD TABLE ENDS -->
      <!-- DISPTACH DETAILS STARTS -->
        <tr>
          <td>AWB Number</td>
          <td colspan="2">{{ getSingleOrderData.awbNumber  }}</td>
        </tr>
        <tr>
          <td>Challan Number</td>
          <td colspan="2">{{ getSingleOrderData.challanNumber  }}</td>
        </tr>
        <tr>
          <td>Courier</td>
          <td colspan="2">{{ getSingleOrderData.courier  }}</td>
        </tr>
        <tr>
          <td>Delivery Status</td>
          <td colspan="2">{{ getSingleOrderData.deliveryStatus  }}</td>
        </tr>
        <tr>
          <td>Delivery Date</td>
          <td colspan="2">{{ getSingleOrderData.deliveryDate | date:'dd-MM-yyyy'  }}</td>
        </tr>
        <tr>
          <td>Dispatch Date</td>
          <td colspan="2">{{ getSingleOrderData.dispatchDate | date:'dd-MM-yyyy' }}</td>
        </tr>
        <!-- DISPTACH DETAILS ENDS -->
        
  </table>
</div>
