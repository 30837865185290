<div class="container mt-4 text-center">
  <h2>Download Billing </h2>
  <div class="row justify-content-center">
    <div class="col-md-4 mb-3">
      <mat-form-field class="w-100">
        <mat-label>Select Month and year</mat-label>
        <mat-select [(ngModel)]="selectedMonth">
          <mat-option *ngFor="let month of months" [value]="month">
            {{ month }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="col-md-4 mb-3">
      <mat-form-field class="w-100">
        <mat-label>Year</mat-label>
        <mat-select [(ngModel)]="selectedYear">
          <mat-option *ngFor="let year of years" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
  </div>
  <button mat-raised-button color="primary" (click)="downloadBilling()">Download Billing</button>
</div>
