<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 m-auto pb-3">
      <mat-card>
        <h3 class="text-center">Modify User</h3>
        <form [formGroup]="userForm">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Select User</mat-label>
            <mat-select formControlName="user" (selectionChange)="selectUser($event)">
              <mat-option *ngFor="let user of users" [value]="user">
                {{user?.email}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="data.user.errors?.required">User is required</mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="w-100">
            <input type="text" matInput placeholder="Emp ID" formControlName="userName" [(ngModel)]="userName" />
            <mat-error *ngIf="data.userName.errors?.required">Emp ID is required</mat-error>
          </mat-form-field> -->
          <mat-form-field class="w-100">
            <input type="text" matInput placeholder="Full Name" formControlName="fullName" [(ngModel)]="fullName" />
            <mat-error *ngIf="data.fullName.errors?.required">Full Name is required</mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="w-100">
            <mat-label>Select Branch</mat-label>
            <mat-select formControlName="branchId">
              <mat-option *ngFor="let branch of branches" [value]="branch?._id">
                {{branch?.branchName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="data.branchId.errors?.required">Branch is required</mat-error>
          </mat-form-field> -->
          <mat-form-field class="w-100">
            <input type="text" matInput placeholder="Email ID" formControlName="email" [(ngModel)]="email" />
            <mat-error *ngIf="data.email.errors?.required">Email ID is required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input type="text" matInput placeholder="Mobile Number" formControlName="mobileNumber"
              [(ngModel)]="mobileNumber" />
            <mat-error *ngIf="data.mobileNumber.errors?.required">Mobile Number is required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Select Role</mat-label>
            <mat-select formControlName="role">
              <mat-option *ngFor="let role of roles" [value]="role?._id">
                {{role?.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="data.role.errors?.required">Role is required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input type="text" matInput placeholder="Designation" formControlName="designation"
              [(ngModel)]="designation" />
            <mat-error *ngIf="data.designation.errors?.required">Designation is required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <input type="text" matInput placeholder="Phone" formControlName="phoneNumber" [(ngModel)]="phoneNumber" />
            <mat-error *ngIf="data.phoneNumber.errors?.required">Phone is required</mat-error>
          </mat-form-field>
          <div class="mt-4 text-center">
            <button mat-raised-button color="primary" class="mx-3" (click)="updateUser()">Update</button>
            <button mat-raised-button color="primary" class="mx-3" (click)="deleteUser()">Delete</button>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>