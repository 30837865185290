import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService, SharedService } from "../../../core";
import { ConfirmPasswordValidator } from "./confirm-password-validator";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  hideNew = true;
  hideConfirm = true;
  hide = true;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  get data() {
    return this.changePasswordForm.controls;
  }

  initForm() {
    this.changePasswordForm = this.fb.group(
      {
        newPassword: ["", { validators: [Validators.required] }],
        confirmPassword: ["", { validators: [Validators.required] }],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.changePasswordForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  changePassword() {
    if (this.changePasswordForm.invalid) {
      this.changePasswordForm.markAllAsTouched();
      return;
    }
    var newPass = this.data.newPassword.value;
    var newConfirmPass = this.data.confirmPassword.value;
    if (newPass && newConfirmPass) {
      if (newPass === newConfirmPass) {
        const updatePassdata = {
          referenceCode: atob(sessionStorage.getItem("referenceCode")),
          newPassword: this.data.newPassword.value,
        };
        // const config = {
        //   url: `${API_URL}auth/update-password`,
        //   data: updatePassdata,
        // };
        this.authService.changePassword(updatePassdata).subscribe(
          (response) => {
            // this.forgotPasswordComponent = false;
            // this.otpComponent = false;
            // this.changePasswordComponent = false;
            this.sharedService.openSuccessDialog(
              `Password has been changed successfully.`,
              ""
            );
            //modal call to show success msg
            this.router.navigate(["/auth/login"]);
          },
          (error) => {
            //modal call to show err msg
          }
        );
      }
    }
  }

  cancelMenu() {
    this.router.navigate(["/auth/login"]);
  }
}
