<div class="container">
    <div class="row">
        <div class="col">
            <mat-form-field class="w-100">
                <mat-label>Reason</mat-label>
                <textarea matInput placeholder="Reason" [(ngModel)]="reason"></textarea>
                <mat-error>Reason is required</mat-error>
                </mat-form-field>
        </div>
    </div>
    <div>
        <div class="row justify-content-end">
            <button mat-button color="accent" (click)="dialogRef.close()">Cancel</button>
            <button mat-button color="primary" (click)="submit()">Done</button>
        </div>
       
    </div>
</div>
