<div class="orderHistoryContainer" >
  <div class=" container d-flex">

    <ul class="nav  row d-flex">
      <li class="nav-item mr-2">
        <button mat-stroked-button (click)="addUser()">Add User</button>
      </li>
      <li class="nav-item mr-2">
        <button mat-stroked-button (click)="modifyUser()">Modify User</button>
      </li>
      <!-- <li class="nav-item mr-2">
        <button mat-stroked-button (click)="uploadBillingMIS()">Upload Billing MIS</button>
      </li> -->
    </ul>
  </div>
  <br>
  <div class="container row d-flex">
    <div class="ml-4">
      <button
  
        mat-button
        
        [class.active]="activeButton === 'ID'"
        (click)="openTab('ID'); setActiveButton('ID')"
      >
        Show ID Card
      </button>
      <button mat-button
      [matMenuTriggerFor]="menu"
      [class.active]="activeButton === 'ID'"
      (click)=" setActiveButton('ID')">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item (click)="loadOrderHistory('ready-for-review')">
          Ready For Review
        </button> -->
        <button mat-menu-item (click)="loadOrderHistory('approved')">
          Approved
        </button>
        <button mat-menu-item (click)="loadOrderHistory('rejected')">
          Rejected
        </button>
      </mat-menu>
    </div>
    <div>
  
      <button
      mat-button
      [matMenuTriggerFor]="menu"
        [class.active]="activeButton === 'Business'"
        (click)="openTab('Business'); setActiveButton('Business')"
      >
        Show Business Card
  
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <button mat-menu-item (click)="loadOrderHistory('ready-for-review')">
          Ready For Review
        </button> -->
        <button mat-menu-item (click)="loadOrderHistory('approved')">
          Approved
        </button>
        <button mat-menu-item (click)="loadOrderHistory('rejected')">
          Rejected
        </button>
      </mat-menu>
    </div>
   
  </div>
  
  <!-- <div class="col-md-2 col-6 mt-md-1">
    <button mat-raised-button (click)="getAllOrders('completed')">
      Approved orders
    </button>
  </div>
  <div class="col-md-2 col-6 mt-md-1">
    <button mat-raised-button (click)="getAllOrders('rejected')">
      Rejected orders
    </button>
  </div> -->
  
  <div  class="orderHistory">
    <div *ngIf="accordionItems.length > 0">
      <mat-accordion #matAccordion>
        <mat-expansion-panel
          *ngFor="let item of accordionItems"
          
          [expanded]="item.selected"
        >
        <mat-expansion-panel-header class="expansionHeader">
          <div class="expansionHeader-content-container">
            <!-- Content inside the header that needs scrolling -->
            <mat-panel-title>
              <div class="groupIdContainer">
                {{
                  orderType === "ID"
                  ? item._id.orderCardGroupId
                  : item._id.businessCardGroupId
                }}
              </div>
              <div class="orderDate">
                <a style="color: rgb(0, 50, 79);"> Order Placed:</a>&nbsp;
                <i>{{ item.orders[0].createdAt | date: "dd-MMM-yyyy" }}</i>
              </div>
              <div
                class="statusContainer"
                [ngClass]="{
                  'blue-background': item.orders[0].status === 'ready-for-review',
                  'red-background': item.orders[0].status === 'rejected',
                  'green-background': item.orders[0].status === 'approved'
                }"
              >
                {{
                  item.orders[0].status.toUpperCase() === 'READY-FOR-REVIEW'
                    ? 'APPROVAL PENDING'
                    : item.orders[0].status.toUpperCase()
                }}
              </div>
            </mat-panel-title>
            <div class="buttonIcon">
              <button
                mat-button
                (click)="downloadExcelForIDCard(item._id.orderCardGroupId)"
                *ngIf="orderType === 'ID'"
                matTooltip="Download Excel"
                matTooltipPosition="above"
              >
                <mat-icon matSuffix style="color: #37b837;">insert_drive_file</mat-icon>
              </button>
        
              <button
                mat-button
                (click)="downloadExcelForBusinessCard(item._id.businessCardGroupId)"
                *ngIf="orderType !== 'ID'"
                matTooltip="Download Excel"
                matTooltipPosition="above"
              >
                <mat-icon matSuffix style="color: green;">insert_drive_file</mat-icon>
              </button>
              <button
                mat-button
                *ngIf="orderType === 'ID'"
                (click)="downloadZip(item._id.orderCardGroupId)"
                matTooltip="Download Zip"
                matTooltipPosition="above"
              >
                <mat-icon matSuffix style="color: #277ae0;">image</mat-icon>
              </button>
              <button
                
              mat-button
              (click)="openUploadDialog(item._id.orderCardGroupId)"
              *ngIf=" role === 'vendor' && orderType == 'ID'"
              matTooltip="Upload Design"
              matTooltipPosition="above"
            >
            <mat-icon matSuffix >design_services</mat-icon>
            </button>
            <button
                
            mat-button
            (click)="openUploadDialog(item._id.businessCardGroupId)"
            *ngIf=" role === 'vendor' && orderType !== 'ID'"
            matTooltip="Upload Design"
            matTooltipPosition="above"
          >
          <mat-icon matSuffix >design_services</mat-icon>
          </button>
            <button  *ngIf="role == 'vendor' && orderType == 'ID'" (click)="downloadSticker(item._id.orderCardGroupId)"   class="mr-2"mat-button>Sticker</button>
            <button  *ngIf="role == 'vendor' && orderType !== 'ID'" (click)="downloadSticker(item._id.businessCardGroupId)"   class="mr-2"mat-button>Sticker</button>
              
            </div>
            
          </div>
        </mat-expansion-panel-header>
        
          
          <div class="table-wrapper">
            <table class="mat-elevation-z8">
              <div *ngIf="orderType === 'ID'">
                <thead>
                  <tr>
                    <th *ngIf="item.orders[0].status !== 'approved' && item.designFileURL !== null && role !== 'vendor'">
                      <input
                        type="checkbox"
                        (change)="toggleGroupSelection(item)"
                      />
                    </th>
                    
                    <th>Order Id</th>
                    <th>Employee Name</th>
                    <th>Employee Code</th>
                    <th>Emergency Contact No.</th>
                    <!-- <th>Office Location (as per PCHFL network list)</th> -->
                    <th>AWB Number</th>
                    <th>Challan No.</th>
                    <th>Courier</th>
                    <th>Delivery Date</th>
                    <th>Delivery Status</th>
                    <th>Dispatch Date</th>
                    <th *ngIf="role === 'vendor'">Dispatch Details</th>
                    <th>Details</th>
                  </tr>
                </thead>
  
                <tbody>
                  <tr *ngFor="let order of item.orders">
                    <td *ngIf="item.orders[0].status !== 'approved' && item.designFileURL !== null && role !== 'vendor'">
                      <input
                        type="checkbox"
                        [checked]="order.isSelected"
                        (change)="toggleOrderSelection(order)"
                      />
                    </td>
                    <td>{{ order.orderUniqueId || "-" }}</td>
                    <td>{{ order.employeeName || "-"}}</td>
                    <td>{{ order.employeeCode || "-" }}</td>
                    <td>{{ order.emergencyContact || "-"}}</td>
                    <!-- <td>{{ order.officeLocation || "-"}}</td> -->
                    <td>{{ order.awbNumber || "-"}}</td>
                    <td>{{ order.challanNumber || "-"}} </td>
                    <td>{{ order.courier || "-"}}</td>
                    <td>{{ order.deliveryDate | date:'dd-MM-yyyy' }}</td>
                    <td>{{ order.deliveryStatus || "-"}}</td>
                    <td>{{ order.dispatchDate | date:'dd-MM-yyyy'}}</td>
                    
                    <td *ngIf="role === 'vendor'">
                      <span
                        (click)="openPopup(order._id)"
                        class="pointer text-primary"
                        ><u><mat-icon>edit</mat-icon></u></span
                      >
                    </td>
  
                    <td>
                      <span
                        (click)="viewDetails(order._id, orderType)"
                        class="pointer text-primary"
                        ><u>View Details</u></span
                      >
                    </td>
                  </tr>
                </tbody>
              </div>
  
              <div *ngIf="orderType === 'Business'">
                <thead>
                  <tr>
                    
                    <th>Order Id</th>
                    <th>Employee Name</th>
                    <th>Employee Code</th>
                    <th>Contact No.</th>
                    <!-- <th>Office Location (as per PCHFL network list)</th> -->
                    <th>AWB Number</th>
                    <th>Challan No.</th>
                    <th>Courier</th>
                    <th>Delivery Date</th>
                    <th>Delivery Status</th>
                    <th>Dispatch Date</th>
                    <th *ngIf="role === 'vendor'">Dispatch Details</th>
                    <th>Details</th>
                  </tr>
                </thead>
  
                <tbody>
                  <tr *ngFor="let order of item.orders">
  
                    <td>{{ order.orderUniqueId || "-" }}</td>
                  <td>{{ order.employeeName || "-"}}</td>
                  <td>{{ order.employeeId || "-" }}</td>
                  <td>{{ order.mobileNumber || "-"}}</td>
                  <!-- <td>{{ order.officeLocation || "-"}}</td> -->
                  <td>{{ order.awbNumber || "-"}}</td>
                  <td>{{ order.challanNumber || "-"}} </td>
                  <td>{{ order.courier || "-"}}</td>
                  <td>{{ order.deliveryDate | date:'dd-MM-yyyy' }}</td>
                  <td>{{ order.deliveryStatus || "-"}}</td>
                  <td>{{ order.dispatchDate | date:'dd-MM-yyyy'}}</td>
  
                    <td *ngIf="role === 'vendor'">
                      <span
                        (click)="openPopup(order._id)"
                        class="pointer text-primary"
                        ><u><mat-icon>edit</mat-icon></u></span
                      >
                    </td>
                    <td>
                      <span
                        (click)="viewDetails(order._id, orderType)"
                        class="pointer text-primary"
                        ><u>View Details</u></span
                      >
                    </td>
                  </tr>
                </tbody>
              </div>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div *ngIf="accordionItems.length <= 0">
      <div class="no-data-found-container">
        <h3>No Data Found</h3>
        <p>There is no data available.</p>
      </div>
    </div>
    </div>
  
    <!-- confirmation popup for approve and reject -->
    <!-- <app-popup
      *ngIf="isPopupVisible"
      (okClicked)="onOKClicked($event)"
      (cancelClicked)="onCancelClicked()"
    ></app-popup> -->
    <!-- <app-popup></app-popup> -->
    <!-- <app-reason-pop-up></app-reason-pop-up> -->
  </div>
  
  <style>
    .orderHistory {
      margin: 40px auto;
    }
  
    .table-wrapper {
      overflow-x: auto;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
    }
  
    th,
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      background-color: #f2f2f2;
      font-weight: bold;
    }
  
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  
    tr:hover {
      background-color: #f5f5f5;
    }
  </style>
  