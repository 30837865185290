<div class="main-container">
    <mat-toolbar class="" color="primary">
      <mat-toolbar-row class="justify-content-between">
        <img class="logo pointer" src="assets/header-logo.png" alt="Logo" [routerLink]="['/home']">
        <button [routerLink]="['/auth/login']" mat-button *ngIf="url == '/auth/register'">Back</button>
      </mat-toolbar-row>
    </mat-toolbar>
  
    <div class="auth-card">
      <div class="d-flex align-items-center ">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-6">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <mat-toolbar class="footer-toolbar" color="primary">
    <mat-toolbar-row class="justify-content-between">
      <span>Copyright © {{year}}<a href="https://www.parasprint.in/"> ParasPrint</a></span>
    </mat-toolbar-row>
  </mat-toolbar>
  