<mat-card>
    <h3 class="text-center">Change Password</h3>
    <form [formGroup]="changePasswordForm">
        <mat-form-field class="w-100">
            <mat-label>Enter your password</mat-label>
            <input matInput [type]="hideNew ? 'password' : 'text'" formControlName="newPassword">
            <button mat-icon-button matSuffix (click)="hideNew = !hideNew" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="data.newPassword.errors?.required">Password is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Confirm Password</mat-label>
            <input matInput [type]="hideConfirm ? 'password' : 'text'" formControlName="confirmPassword">
            <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="data.confirmPassword.errors?.required">Confirm Password is required</mat-error>
            <mat-error
                *ngIf="data.confirmPassword.errors && data.confirmPassword.errors?.ConfirmPassword">
                <span>New Passsword and Confirm Password didn't match.</span>
            </mat-error>
        </mat-form-field>
        <div class="mt-4 text-center">
            <button class="btn btn-block" mat-raised-button color="primary" (click)="changePassword()">Submit</button>
        </div>
        <div class="mt-4 text-right">
            <a class="ml-2" mat-stroked-button (click)="cancelMenu()">Cancel</a>
        </div>
    </form>
</mat-card>