<div class="main-container d-flex flex-column h-auto">
  <div class="registration-form m-auto h-100 ">
    <mat-card>
      <h3 class="text-center">Add New User</h3>
      <form [formGroup]="registerForm">
        
        
        <mat-form-field class="w-100">
          <input
            type="text"
            matInput
            placeholder="Full Name"
            formControlName="fullName"
            [(ngModel)]="fullName"
            
            disabled
          />
        </mat-form-field>
        <mat-form-field class="w-100">
          <input
            type="text"
            matInput
            placeholder="Designation"
            formControlName="designation"
            [(ngModel)]="designation"
          />
          <mat-error *ngIf="registerForm.controls.designation.errors?.required">
            Desigantion is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <input
            type="email"
            matInput
            placeholder="Email ID"
            formControlName="email"
            [(ngModel)]="email"
          />
          <mat-error *ngIf="registerForm.controls.email.errors?.required">
            Email is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <input
            type="number"
            matInput
            placeholder="Mobile Number"
            formControlName="mobileNumber" 
            [(ngModel)]="mobileNumber"
          />
          <mat-error *ngIf="registerForm.controls.mobileNumber.errors?.required">
            Mobile Number is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <input
            type="text"
            matInput
            placeholder="Location"
            formControlName="location"
            [(ngModel)]="location"
          />
          <mat-error *ngIf="registerForm.controls.location.errors?.required">
            Location is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <input
            type="text"
            matInput
            placeholder="Entity"
            formControlName="entity"
            [(ngModel)]="entity"
          />
          <mat-error *ngIf="registerForm.controls.entity.errors?.required">
            Entity is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <input
            type="text"
            matInput
            placeholder="Username"
            formControlName="userName"
            [(ngModel)]="userName"
          />
          <mat-error *ngIf="registerForm.controls.userName.errors?.required">
            Username is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <input
            type="password"
            matInput
            placeholder="Password"
            formControlName="password"
            [(ngModel)]="password"
          />
          <mat-error *ngIf="registerForm.controls.password.errors?.required">
            Password is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-select
            placeholder="Role"
            formControlName="role"
            [(ngModel)]="role"
          >
            <mat-option value="admin">Admin</mat-option>
            <mat-option value="user">User</mat-option>
            
          </mat-select>
          <mat-error *ngIf="registerForm.controls.role.errors?.required">
            Role is required
          </mat-error>
        </mat-form-field>
        <div class="mt-4">
          <button (click)="onSubmit()" mat-raised-button color="primary">Register</button>
          <a [routerLink]="['/login']" class="ml-2" mat-button color="primary">Cancel</a>
        </div>
      </form>
    </mat-card>
  </div>

  <div class="mt-4">
    <mat-toolbar class="position-absolute" color="primary">
      <mat-toolbar-row class="justify-content-between">
        <span>Copyright © <a href="https://www.parasprint.in/">ParasPrint</a></span>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</div>