import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AngularMaterialModule } from '../../partials/angular-material/angular-material.module';
import { PartialsModule } from '../../partials/partials.module';
import { OrderHistoryComponent } from './order-history.component';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {TooltipPosition, MatTooltipModule} from '@angular/material/tooltip'; 

// import { DateTimePipe } from 'src/app/pipes/datetime.pipe';


const routes: Routes = [
  { path: '', component: OrderHistoryComponent },
  {
    path: 'view-details/:id/:type', component: ViewDetailsComponent,
  },
]

@NgModule({
  declarations: [OrderHistoryComponent, ViewDetailsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    AngularMaterialModule,
    PartialsModule,
    MatButtonModule,
    MatMenuModule,
    MatExpansionModule,
    MatTooltipModule,
  ]
})
export class OrderHistoryModule { }
