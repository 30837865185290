import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartialsModule } from '../../partials/partials.module';
import { AngularMaterialModule } from '../../partials/angular-material/angular-material.module';
import { AddUserComponent } from './add-user/add-user.component'
import { ViewDetailsComponent } from './view-details/view-details.component'
import { AdminComponent } from './admin.component'
import { RouterModule, Routes } from '@angular/router';
import { ReasonPopUpComponent } from './reason-pop-up/reason-pop-up.component';
import { ModifyUserComponent } from './modify-user/modify-user.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import {TooltipPosition, MatTooltipModule} from '@angular/material/tooltip';
import { DownloadBillingComponent } from './download-billing/download-billing.component'; 

const routes: Routes = [
  { path: '', component: AdminComponent },
  {
    path: 'view-details/:id/:type', component: ViewDetailsComponent,
  },
  {
    path: 'add-user', component: AddUserComponent
  },
  {
    path: 'modify-user', component: ModifyUserComponent
  },
  {
    path: 'download-billing', component: DownloadBillingComponent
  }
]

@NgModule({
  declarations: [
    AddUserComponent,
    ViewDetailsComponent,
    AdminComponent,
    ReasonPopUpComponent,
    ModifyUserComponent,
    DownloadBillingComponent,
  ],
  imports: [
    CommonModule,
    PartialsModule,
    AngularMaterialModule,
    MatButtonModule,
    MatMenuModule,
    RouterModule.forChild(routes),
    MatExpansionModule,
    MatTooltipModule
  ],
  exports: [RouterModule],
  entryComponents: [
    ReasonPopUpComponent
  ]
})
export class AdminModule { }
