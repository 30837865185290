import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AuthService, RegexValidators } from "../../../core";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgetpasswordForm: FormGroup;
  hide = true;
  hideOTP = true;
  hideNew = true;
  hideConfirm = true;
  submitted = false;
  loginComponent = true;
  forgotPasswordComponent = false;
  otpComponent = false;
  changePasswordComponent = false;
  showSpinner: boolean = false;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  public username;
  public password;
  emailId: any;
  token: any;
  userRole: any;
  sendOtpApiRefCode: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initLoginForm();
    sessionStorage.clear();
  }

  initLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: [
        "",
        [Validators.required, Validators.pattern(RegexValidators.email)],
      ],
      // emailId: ["", [Validators.required, Validators.pattern(RegexValidators.email)]],
      password: ["", Validators.required],
      // otp: ["", Validators.required],
    });
  }

  route() {
    this.router.navigate(["/auth/register"]);
  }

  get data() {
    return this.loginForm.controls;
  }

  get dataForgetPassword() {
    return this.forgetpasswordForm.controls;
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    // else if (this.data.username.value == sessionStorage.getItem("username") && this.data.password.value == sessionStorage.getItem("password")) {
    //   this.router.navigate(['/home']);
    // } else {
    //   this.submitted = true;
    // }
    const data = {
      email: this.data.username.value,
      password: this.data.password.value,
    };

    this.authService.login(data).subscribe(
      (response) => {
        sessionStorage.setItem("UserDetails", JSON.stringify(response.data));
        this.token = response["data"]["token"];
        this.userRole = response["data"]["role"];
        if (this.token) {
          if (this.userRole === "admin") {
            this.router.navigate(["/admin"]);
          } else if (this.userRole === "vendor") {
            this.router.navigate(["/vendor"]);
          } else {
            this.router.navigate(["/ordercard"]);
          }
        }
      },
      (error) => {
        console.log(error);
        // alert(`${error.statusText}: Email Id or password is not valid. Please try again !`);
        //modal call to show err msg
      }
    );
  }

  sendOtp() {
    if (this.data.username.invalid) {
      this.data.username.markAsTouched();
      return;
    }
    const sendOtpdata = {
      email: this.data.username.value,
      otpFor: "login",
    };
    this.authService.sendOtp(sendOtpdata).subscribe(
      (response) => {
        this.router.navigate(["/auth/otp-login"], {
          queryParams: { email: sendOtpdata.email },
        });
        sessionStorage.setItem(
          "referenceCode",
          btoa(response["referenceCode"])
        );
      },
      (error) => {
        //modal call to show err msg
        // alert(`${error.statusText}: Email Id is not valid. Please try again !`);
      }
    );
  }

  loadSpinner() {
    this.showSpinner = true;
    setTimeout(() => {
      this.showSpinner = false;
    }, 2000);
  }

  openForgotPasswordDialog() {
    // this.loadSpinner();
    this.router.navigate(["auth/forgot"]);
    this.loginComponent = false;
    this.forgotPasswordComponent = true;
  }

  openRegisterDialog() {
    // this.loadSpinner();
    this.router.navigate(["auth/register"]);
    this.loginComponent = false;
    this.forgotPasswordComponent = false;
  }

  /**Call for Verify otp */

  // openChangePasswordDialog() {
  //   // this.loadSpinner();
  //   this.otpComponent = false;
  //   this.changePasswordComponent = true;
  // }

  /**Call for Chnage password */

  cancelMenu() {
    this.loginComponent = true;
    this.forgotPasswordComponent = false;
    this.otpComponent = false;
    this.changePasswordComponent = false;
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(ModalComponent,{restoreFocus: false, disableClose: true,height:'300px',width:'500px'});

  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }
}
