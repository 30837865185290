import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component'
import { RegisterComponent } from './register/register.component'
import { AngularMaterialModule } from '../../partials/angular-material/angular-material.module'
import { RouterModule, Routes } from '@angular/router';
import { PartialsModule } from '../../partials/partials.module';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OtpComponent } from './otp/otp.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login', component: LoginComponent,
      },
      {
        path: 'forgot', component: ForgotPasswordComponent,
      },
      {
        path: 'otp', component: OtpComponent,
      },
      {
        path: 'otp-login', component: OtpComponent,
      },
      { 
        path: 'change-password', component: ChangePasswordComponent 
      },
      {
        path: 'register', component: RegisterComponent,
      },
    ]
  }
]
@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    AuthComponent,
    ForgotPasswordComponent,
    OtpComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    PartialsModule,
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class AuthModule { }
