import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    private orderService : OrderService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  fileToUpload: File | null = null;

  ngOnInit() {}

  onFileSelected(event: any) {
    // Handle file selection logic here
    this.fileToUpload = event.target.files[0];
  }

  onUpload() {
    // Handle upload logic here
    if(this.fileToUpload){
      const orderType = this.data.orderType;
      const uniqueId = this.data.uniqueId;
      const formData: FormData = new FormData();
      formData.append('file', this.fileToUpload);
      this.orderService.uploadDesign(formData,orderType,uniqueId).subscribe(
        (response) => {
          this.toastr.success('File Uploaded Successfully!', 'Success');
        },
        (error) => {
          console.error('Error uploading file:', error);
        }
      );
      this.dialogRef.close(true);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
