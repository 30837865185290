import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize, catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class InterceptService implements HttpInterceptor {

	constructor(
		private authService: AuthService,
		private router: Router,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.authService.getToken();
		if (this.authService.isLoggedIn()) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			});
		}
		return next.handle(request).pipe(
			tap(
				event => {
					
				},
				error => { }
			),
			catchError(
				err => {
					if (err.status == 403) {
						sessionStorage.clear();
						this.router.navigate(['/auth/login']);
					}
					if (err.status == 502) {
						window.location.reload();
					}
					throw err;
				}
			),
			finalize(() => {
				// this.sharedSerivce.loader.next(false)
			})
		);
	}
}
