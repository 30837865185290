import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderCardComponent } from './order-card.component';
import { RouterModule, Routes } from '@angular/router';
import { AngularMaterialModule } from 'src/app/partials/angular-material/angular-material.module';
import { PartialsModule } from 'src/app/partials/partials.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
const routes: Routes = [
  { path:'',component: OrderCardComponent},
  
]


@NgModule({
  declarations: [
    OrderCardComponent,
  ],
  imports: [
    CommonModule,
    PartialsModule,
    AngularMaterialModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule, MatRippleModule,
    RouterModule.forChild(routes),
    MatExpansionModule
  ],
  providers:[
    MatDatepickerModule,
    MatNativeDateModule  
  ]
})
export class OrderCardModule { }
