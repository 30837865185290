<div class="main-container d-flex flex-column h-auto">
  <div class="registration-form m-auto h-100">
    <mat-card>
      <h3 class="text-center">Register</h3>
      <form [formGroup]="registerForm">
        <mat-form-field class="w-100">
          <input type="text" matInput placeholder="Full Name" formControlName="fullName" [(ngModel)]="fullName"
            disabled />
        </mat-form-field>

        <mat-form-field class="w-100">
          <input type="email" matInput placeholder="Email ID" formControlName="email" [(ngModel)]="email" />
          <mat-error *ngIf="registerForm.controls.email.errors?.required">
            Email is required
          </mat-error>
          <mat-error *ngIf="registerForm.controls.email.errors?.email">
            Invalid email format
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
          <input type="tel" matInput placeholder="Mobile Number" formControlName="mobileNumber"
            [(ngModel)]="mobileNumber" />
          <mat-error *ngIf="registerForm.controls.mobileNumber.errors?.required">
            Mobile Number is required
          </mat-error>
          <mat-error *ngIf="registerForm.controls.mobileNumber.errors?.pattern">
            Invalid mobile number format
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
          <input type="password" matInput placeholder="Password" formControlName="password" [(ngModel)]="password" />
          <mat-error *ngIf="registerForm.controls.password.errors?.required">
            Password is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
          <input type="password" matInput placeholder="Confirm Password" formControlName="confirmPassword"
            [(ngModel)]="confirmPassword" />
          <mat-error *ngIf="registerForm.controls.confirmPassword.errors?.required">
            Confirm Password is required
          </mat-error>
          <mat-error *ngIf="registerForm.hasError('passwordMismatch')">
            Passwords do not match
          </mat-error>
        </mat-form-field>

        <div class="mt-4">
          <button (click)="onSubmit()" mat-raised-button color="primary">Register</button>
          <a [routerLink]="['/login']" class="ml-2" mat-button color="primary">Cancel</a>
        </div>
      </form>
    </mat-card>
  </div>
</div>
