import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedService } from '../shared/shared.service';
import {API_URL } from '../../../app-constant'
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  isLoggedIn() {
    return !!sessionStorage.getItem('UserDetails');
  }
  getToken() {
    let token = JSON.parse(sessionStorage.getItem('UserDetails'));
    if (token)
      return token.token
  }

  login(data: any): Observable<any> {
    return this.http.post(`${API_URL}/auth/login`, data).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    );
  }
  verfiyLoginOtp(data: any): Observable<any> {
    return this.http.post(`${API_URL}/auth/login-otp`, data).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    );
  }

  sendOtp(data: any): Observable<any> {
    return this.http.post(`${API_URL}/auth/send-otp`, data).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, ``)
        throw err
      })
    );
  }

  verfiyOtp(data: any): Observable<any> {
    return this.http.post(`${API_URL}/auth/verify-otp`, data).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    );
  }
  register(data: any): Observable<any> {
    return this.http.post(`${API_URL}/user`, data).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    );
  }

  changePassword(data: any): Observable<any> {
    return this.http.post(`${API_URL}/auth/update-password`, data).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    );
  }

  getBranch(): Observable<any> {
    return this.http.get(`${API_URL}/branch`).pipe(
      map(res => res),
      catchError(err => {
        if (err.error.message)
          this.sharedService.openErrorDialog(err.error.message, '')
        throw err
      })
    );
  }
}
